import React, { useState } from 'react';
import { TextField, Box, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ReactDOM from 'react-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Popper from '@mui/material/Popper';
import { serviceOpsShipmentActions } from '../../../_actions';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import { TurnedIn } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';


const PriceEditCell = ({ id, value, api, field, state, addPricingDetail }) => {
    const dispatch = useDispatch();

    const [comment, setComment] = useState('');
    const [anchorEl, setAnchorEl] = useState(null)
    const [open, setOpen] = useState(false)
    const [placement, setPlacement] = useState()
    const [pricingDetailName, setPricingDetailName] = useState('')

    const handlePriceChange = (event) => {
        api.setEditCellValue({ id, field, value: event.target.value });
    };



    const handleClick = (newPlacement) => (event) => {
        dispatch(serviceOpsShipmentActions.getPricingDetails(id));
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const handleDetailNameChange = (event) => {
        setPricingDetailName(event.target.value)
    }


    const columns = [
        // { headerName: "ID", field: "id", width: 60 },
        { headerName: "Name", field: "name", flex: 2},
        { headerName: "Value", field: "value", flex: 1 },
    ];
    const rows = state.selectedShipment.pricingDetails.map(x => (
        {
            ...x,
            id: x.name,
            name: x.name,
            value: x.value
        }))

    const totalDetails = rows.reduce((acc, row) => acc + row.value, 0)
    const priceDifference = Number(value - totalDetails).toFixed(2)

    return (
        <Box display='flex' alignItems='center' sx={{width: '100%'}}>
            <TextField
                sx={{width: '100%', height: '100% !important', 
                    '& .MuiInputBase-root': {
                        height: '100% !important', 
                    },
                    '& input': {
                        height: '100% !important', 
                    },
                }}
                value={value}
                onChange={handlePriceChange}
                size="small"
                variant="outlined"
                onFocus={handleClick('bottom')}
            />
            <Popper open={open} placement={placement} anchorEl={anchorEl} >
                <Paper sx={{ marginTop: '15px', width: '300px' }}>
                    <DataGrid
                        hideFooter
                        autoHeight
                        rowHeight={30}
                        rows={rows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        disableRowSelectionOnClick
                        sx={{
                            fontSize: '10px', 
                            '& .MuiDataGrid-cell': {
                              padding: '0px 8px', 
                            },
                            '& .MuiDataGrid-columnHeaders': {
                              fontSize: '11px', 
                              padding: '4px 8px', 
                            },
                            '& .MuiDataGrid-row': {
                              minHeight: '30px !important', 
                            },
                            '& .MuiDataGrid-columnHeader': {
                              minHeight: '30px !important', 
                            },
                        }}
                        loading={state.pricingDetailsLoading}
                        slotProps={{
                          loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                          },
                        }}
                    />
                    <Grid container>
                        <Grid container xs={12} p={0.5}>
                        <Grid item xs={7} pr={1}>
                            <TextField size="small" sx={{width: '100%'}} placeholder='Detail name' value={pricingDetailName} onChange={handleDetailNameChange} />
                        </Grid>
                        <Grid item xs={4} pr={1}>
                            <TextField size="small" sx={{width: '100%' }} value={priceDifference} />
                        </Grid>
                        <Grid item xs={1}>
                            <AddIcon onClick={() => addPricingDetail(id, pricingDetailName, priceDifference)}/>
                        </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Popper>

        </Box>

    );
};

export default PriceEditCell;