
import  React from 'react';
import SuiBox from "_softUI/components/SuiBox";
import SuiTypography from "_softUI/components/SuiTypography";
import SuiInput from "_softUI/components/SuiInput";
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';


function AutocomplateWithLabel({ label, adornment, adornmentPosition, fontWeight,mandatory, placeholder, changeValue, value, error,locations,onSelect, ...rest }) {

  const filter = createFilterOptions();
  const direction = adornmentPosition === "end" ? "right" : "left"
  return (
    <>
      <SuiBox mb={0} ml={0} lineHeight={0} sx={{marginBottom: '0rem'}} display="inline-block">
        <SuiTypography
          component="label"
          variant="caption"
          fontWeight={fontWeight}
          textTransform="capitalize"
        >
          {label}{mandatory&&  <SuiTypography
          component="label"
          variant="caption"
          fontWeight='light'
          textTransform="capitalize"
          sx={{color:'#fd5c70'}}
         
        >
           *
        </SuiTypography>}
        </SuiTypography>
      </SuiBox>
      <Autocomplete
        name="name"
        value={value}
        onChange={(event, newValue) => {
          console.log('change')
     
          onSelect(newValue, rest.index);
       }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          return filtered;
        }}
        selectOnFocus

        handleHomeEndKeys

        options={locations}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.label;
        }}
        renderOption={(props, option) => <li {...props}>{option.label}</li>}
        sx={{ width: '100%', mt: '0rem' }}
        freeSolo
        renderInput={(params) => (
          <SuiInput 
          {...params}
          {...rest} 
          ref={params.InputProps.ref}
          placeholder={placeholder} 
          fullWidth 
          error={error} 
          fromAutoComplate={true}
          icon={ adornment && {component: <InputAdornment sx={{height:'1.2em', fontSize:'1.1rem',color:'#183B59',ml:0 }} position={adornmentPosition}>{adornment}</InputAdornment>, direction: direction}}

          />

  )}
      />

    </>
  );
}



export default AutocomplateWithLabel;

