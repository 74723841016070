import { DatasetSharp, FormatColorReset } from '@mui/icons-material';
import { serviceOpsShipmentConstants } from '../../_constants';
import { goodsRowModel, adrRowModel } from '../models';
import { dataURItoBlob } from 'dropzone';
import { validate, validateLocations, validateBasicInfo, validateAdrRow } from '../../_helpers'
import { data } from 'jquery';
import { GiConsoleController } from 'react-icons/gi';

const initialState = {
    modalOpen: false,
    modalLoading: false,
    loading: false,
    pricingDetailsLoading: false,
    data: {
        rows: []
    },
    newPricingDetailName: '',
    newPricingDetailValue: 0,
    newAdditionalService: '',
    newAdditionalServiceName: '',
    newAdditionalServiceValue: 0,
    carrierOptions: [],
    editedData: {},
    invoicingInformation: {
        selectedCarrier: '',
        rows: [],
        excelRows: [],
    },
    selectedShipment: {
        carrierLogoUrl: '',
        senderReference: '',
        receiverReference: '',
        pickupStart: '',
        deliveryStart: '',
        messageToCarrier: '',
        selectedCarrierName: '',
        selectedCarrierId: 0,
        selectedCarrierLogo: '',
        totalPrice: '',
        docFwdCustomerReceiver: '',
        docFwdCustomerLang: '',
        docFwdSenderReceiver: '',
        file: [],
        adr: false,
        pricingDetails: [],
        locations: [
            {
                title: '',
                name: '',
                streetAddress: '',
                addressInfo: '',
                city: '',
                openingHours: '',
                contactName: '',
                contactEmail: '',
                contactPhone: '',
                messageToContact: '',
                zip: '',
                countryAlpha2: '',
            },
            {
                title: '',
                name: '',
                streetAddress: '',
                addressInfo: '',
                city: '',
                openingHours: '',
                contactName: '',
                contactEmail: '',
                contactPhone: '',
                messageToContact: '',
                zip: '',
                countryAlpha2: '',
            }
        ],
        goods: [
            goodsRowModel
        ],
        quotes: [

        ]
    }
};

const integerRegExp = /^$|^\d*[0-9]\d*$/

export function serviceOpsShipment(state = initialState, action) {
    switch (action.type) {
        case serviceOpsShipmentConstants.LOADING:
            return {
                ...state,
                loading: true
            }

        case serviceOpsShipmentConstants.PRICINGDETAILS_LOADING:
            return {
                ...state,
                pricingDetailsLoading: true
            }

        case serviceOpsShipmentConstants.ERROR:
            return {
                ...state,
                loading: false
            };


        case serviceOpsShipmentConstants.UPDATE_BOOKINGS_TABLE:

            return {
                ...state,
                loading: false,
                tableUpdateLoading: false,
                data: {
                    ...state.data,
                    rows: action.data
                }
            };


        case serviceOpsShipmentConstants.UPDATE_SELECTED_SHIPMENT_IN_TABLE:

            if (action.data.type === 'form') {
                action.row = mapModelToTableRow(action.data.row)
            }
            if (action.data.type === 'pricing') {
                action.data.shipment = {
                    ...action.data.shipment,
                    purchasePrice: action.data.billingInformation.price,
                    locations: state.selectedShipment.locations.map(location => ({
                        ...location,
                        country: {
                            Id: location.countryId,
                            Name: location.country,
                            Alpha2: location.countryAlpha2
                        }
                    }))
                }
                action.row = mapModelToTableRow(action.data.shipment)
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    rows: state.data.rows.map(row =>
                        row.id === action.row.id
                            ? {
                                ...row,
                                ...action.row
                            }
                            : row
                    )
                }
            };

        case serviceOpsShipmentConstants.UPDATE_CARRIER_OPTIONS:
            const extractedData = action.data.map(item => ({ id: item.id, name: item.name }));
            return {
                ...state,
                tableUpdateLoading: false,
                carrierOptions: extractedData
            };


        case serviceOpsShipmentConstants.CLEAR:
            return {
                ...state,
                selectedShipment: {
                    ...initialState.selectedShipment
                }
            };

        case serviceOpsShipmentConstants.UPDATE_SELECTED_SHIPMENT:
            action.data.purchasePrice = action.purchasePrice
            action.data.pickupStart = action.data.pickupStart.split('T')[0];
            action.data.carrier = { value: state.carrierOptions.find(x => x.id === action.data.selectedCarrierId).id, label: state.carrierOptions.find(x => x.id === action.data.selectedCarrierId).name }

            if (action.data.adr) {
                action.data.goods.forEach(row => {
                    if (row.adrRow.length > 0) {
                        row.adr = true
                    } else {
                        row.adr = false
                    }
                })
            } else {
                action.data.goods.forEach(row => {
                    row.adr = false
                })
            }

            return {
                ...state,
                loading: false,
                selectedShipment: action.data,
                modalLoading: false,

            };

        case serviceOpsShipmentConstants.UPDATE_SELECTED_PRICING_DETAILS:

            return {
                ...state,
                pricingDetailsLoading: false,
                newPricingDetailName: '',
                newPricingDetailValue: 0,
                selectedShipment: {
                    ...state.selectedShipment,
                    pricingDetails: action.data
                }
            };

        case serviceOpsShipmentConstants.MODAL_LOADING:
            return {
                ...state,
                modalLoading: action.value,
                modalOpen: true
            };

        case serviceOpsShipmentConstants.CLOSE_MODAL:
            return {
                ...state,
                selectedShipment: initialState.selectedShipment,
                modalOpen: false,
            };


        case serviceOpsShipmentConstants.UPDATE_SELECTED_SHIPMENT_VALUE:
            const newName = action.data.name
            const newValue = action.data.value

            switch (action.data.type) {
                case 'location':
                    const locToChange = state.selectedShipment.locations[action.data.index]
                    const changedLocation = {
                        ...locToChange,
                        [newName]: newValue,
                        [newName.concat('Error')]: false
                    }
                    const newLocation = state.selectedShipment.locations.map((loc, index) =>
                        index !== action.data.index ? loc : changedLocation
                    )
                    return {
                        ...state,
                        selectedShipment: {
                            ...state.selectedShipment,
                            locations: newLocation
                        }
                    }
                case 'basic':

                    if (newName === 'shipmentType' && state.selectedShipment.shipmentType !== undefined) {
                        if (newValue !== state.selectedShipment.shipmentType) {
                            return {
                                ...initialState
                            }
                        }
                    }

                    if (newName === 'goodsValue' && newValue.match(/^[0-9]+$/) === null) {
                        return { ...state }
                    }

                    if (newName === 'carrier') {
                        return {
                            ...state,
                            selectedShipment: {
                                ...state.selectedShipment,
                                [newName]: newValue,
                                [newName.concat('Error')]: false,
                                selectedCarrierId: newValue.value,
                                selectedCarrierName: newValue.label
                            }
                        }
                    }

                    return {
                        ...state,
                        selectedShipment: {
                            ...state.selectedShipment,
                            [newName]: newValue,
                            [newName.concat('Error')]: false
                        }
                    }

                case 'goodsLine':
                    const lineToChange = state.selectedShipment.goods[action.data.index]
                    const changedLine = {
                        ...lineToChange,
                        [newName.concat('Error')]: newName === 'description' ? false : !integerRegExp.test(newValue),
                        [newName]: newValue
                    }

                    let validatedRow = validate(changedLine)
                    const awaitingAdrClass = newName === 'adr' ? newValue : state.awaitingAdrClass

                    if (newName === 'adr') {
                        if (!newValue) {
                            validatedRow = {
                                ...validatedRow,
                                adrRow: []
                            }
                        }
                        else {
                            validatedRow = {
                                ...validatedRow,
                                adrRow: [adrRowModel]
                            }
                        }
                    }

                    const newLines = state.selectedShipment.goods.map((loc, index) =>
                        index !== action.data.index ? loc : validatedRow
                    )

                    return {
                        ...state,
                        awaitingAdrClass: awaitingAdrClass,
                        selectedShipment: {
                            ...state.selectedShipment,
                            goods: newLines,
                            adr: newLines.some(goodsRow => goodsRow.adr === true),
                            goodsCalculationTrigger: state.selectedShipment.goodsCalculationTrigger + 1
                        }
                    }

                case 'typeCode':

                    let length = state.selectedShipment.goods[action.data.index].length
                    let width = state.selectedShipment.goods[action.data.index].width
                    let stack = state.selectedShipment.goods[action.data.index].stackable
                    let itemType = "Parcel"
                    switch (newValue) {
                        case 1: //Colli

                            itemType = "Colli"
                            break;

                        case 2: //Eur
                            length = 120
                            width = 80
                            itemType = "Eur"
                            break;
                        case 3: //Fin
                            length = 120
                            width = 100
                            itemType = "Fin"
                            break;
                        case 4: //Half
                            length = 60
                            width = 80
                            itemType = "Half"
                            break;
                        case 5:
                            itemType = "Full Trailer"
                            break;
                        case 6:
                            itemType = "Container"
                            break;
                        case 7: //Parcel
                            stack = true;
                            break;

                    }

                    return {
                        ...state,
                        selectedShipment: {
                            ...state.selectedShipment,
                            goods: state.selectedShipment.goods.map((loc, index) => index !== action.data.index ? loc : validate({
                                ...state.selectedShipment.goods[action.data.index],
                                [newName]: newValue,
                                itemType: itemType,
                                length: length,
                                width: width,
                                stackable: stack,
                                lengthError: false,
                                widthError: false,
                            }))
                        }
                    }
                case 'billingWeight':

                    return {
                        ...state,
                        calculating: false,
                        selectedShipment: {
                            ...state.selectedShipment,
                            totalWeight: action.data.data.weight,
                            totalLdm: action.data.data.ldm,
                            totalVolume: action.data.data.volume,
                            billingWeight: action.data.data.billingWeight,
                            errorMessage: action.data.data.message,
                            showError: !action.data.data.valid,
                            showWarning: action.data.data.warning
                        }
                    }

                default:
                    return state

            };

        case serviceOpsShipmentConstants.UPDATE_ADR_ROW:
            const { index, name, value, adrIndex } = action.data;


            const newGoods = state.selectedShipment.goods.map((goodsRow, i) => {
                if (i === index) {
                    const newGoodsRow = {
                        ...goodsRow,
                        //adrClass: name === 'class' ? goodsRow.adrClass.concat(value) : goodsRow.adrClass
                    };
                    const newAdrRow = { ...newGoodsRow.adrRow[adrIndex] };
                    newAdrRow[name] = value;
                    if (name === 'description') {
                        newAdrRow.subsidiaryRisk = action.data.subsidiaryRisk;
                        newAdrRow.technicalNameRequired = action.data.technicalNameRequired;
                        newAdrRow.tunnelCode = action.data.tunnelCode;
                        newAdrRow.packingGroup = action.data.packingGroup;
                    }
                    const validatedAdrRow = validateAdrRow(newAdrRow);
                    newGoodsRow.adrRow[adrIndex] = validatedAdrRow;
                    return newGoodsRow;
                }
                return goodsRow;
            });

            const validatedGoods = newGoods.map(goodsRow => {
                return {
                    ...goodsRow,
                    valid: validate(goodsRow)
                }
            })

            const awaitingAdrClass = validatedGoods.some(item => item.adrRow.some(row => row.class === ''))

            return {
                ...state,
                awaitingAdrClass: awaitingAdrClass,
                selectedShipment: {
                    ...state.selectedShipment,
                    goods: validatedGoods,
                    goodsCalculationTrigger: name === 'class' ? state.data.goodsCalculationTrigger + 1 : state.data.goodsCalculationTrigger
                },
            };

        case serviceOpsShipmentConstants.UPDATE_UNCODE_OPTIONS:
            return {
                ...state,
                selectedShipment: {
                    ...state.selectedShipment,
                    goods: state.selectedShipment.goods.map((goods, goodsIndex) => {
                        if (goodsIndex === action.goodsIndex) {
                            return {
                                ...goods,
                                adrRow: goods.adrRow.map((adrRow, adrIndex) => {
                                    if (adrIndex === action.adrIndex) {
                                        return {
                                            ...adrRow,
                                            unCodeOptions: action.data
                                        };
                                    }
                                    return adrRow;
                                })
                            };
                        }
                        return goods;
                    })
                }
            };
        case serviceOpsShipmentConstants.UPDATE_ADRDESCRIPTION_OPTIONS:
            return {
                ...state,
                selectedShipment: {
                    ...state.selectedShipment,
                    goods: state.selectedShipment.goods.map((goods, goodsIndex) => {
                        if (goodsIndex === action.goodsIndex) {
                            return {
                                ...goods,
                                adrRow: goods.adrRow.map((adrRow, adrIndex) => {
                                    if (adrIndex === action.adrIndex) {
                                        return {
                                            ...adrRow,
                                            adrDescriptionOptions: action.data
                                        };
                                    }
                                    return adrRow;
                                })
                            };
                        }
                        return goods;
                    })
                }
            };
        case serviceOpsShipmentConstants.ADD_ADRLINE:
            const { goodsIndex } = action
            return {
                ...state,
                selectedShipment: {
                    ...state.selectedShipment,
                    goods: state.selectedShipment.goods.map((item, index) => {
                        if (index === goodsIndex) {
                            return {
                                ...item,
                                adrRow: [...item.adrRow, adrRowModel]
                            };
                        }
                        return item;
                    })
                }
            };

        case serviceOpsShipmentConstants.REMOVE_ADRLINE:

            const updatedGoods = state.selectedShipment.goods.map((goodsItem, index) => {
                if (index === action.data.goodsIndex) {
                    const updatedAdrRow = goodsItem.adrRow.filter((row, i) => i !== action.data.adrIndex);
                    return { ...goodsItem, adrRow: updatedAdrRow };
                }
                return goodsItem;
            });

            return {
                ...state,
                selectedShipment: {
                    ...state.selectedShipment,
                    goods: updatedGoods
                }
            };


        case serviceOpsShipmentConstants.UPDATE_LOCATION_INPUT_VALUE:

            return {
                ...state,
                selectedShipment: {
                    ...state.selectedShipment,
                    locations: state.selectedShipment.locations.map((location, idx) =>
                        idx === action.data.index
                            ? {
                                ...location,
                                [action.data.name]: action.data.value,
                            }
                            : location
                    )
                }
            };

        case serviceOpsShipmentConstants.ADD_GOODSLINE:

            return {
                ...state,
                selectedShipment: {
                    ...state.selectedShipment,
                    goods: state.selectedShipment.goods.concat(goodsRowModel)
                }
            };

        case serviceOpsShipmentConstants.REMOVE_GOODSLINE:

            return {
                ...state,
                selectedShipment: {
                    ...state.selectedShipment,
                    goods: state.selectedShipment.goods.filter((row, i) => i !== action.data.index),
                    goodsCalculationTrigger: state.selectedShipment.goodsCalculationTrigger + 1
                }
            };

        case serviceOpsShipmentConstants.ADD_ADDITIONAL_SERVICE:
            const additionalServiceName = action.data.value
            const additionalServicePrice = Number(action.data.price)
            const newPricingDetail = {
                id: additionalServiceName,
                name: additionalServiceName,
                value: additionalServicePrice
            }
            return {
                ...state,
                selectedShipment: {
                    ...state.selectedShipment,
                    pricingDetails: [
                        ...state.selectedShipment.pricingDetails,
                        newPricingDetail
                    ],
                    [additionalServiceName]: true
                }
            };

        case serviceOpsShipmentConstants.ADD_PRICING_DETAIL_TO_STATE:
            const pricingDetail = {
                id: action.data.name,
                name: action.data.name,
                value: Number(action.data.price)
            }
            return {
                ...state,
                selectedShipment: {
                    ...state.selectedShipment,
                    pricingDetails: [
                        ...state.selectedShipment.pricingDetails,
                        pricingDetail
                    ],
                }
            };

        case serviceOpsShipmentConstants.DELETE_PRICING_DETAIL_ROW:
            const newPricingDetails = state.selectedShipment.pricingDetails.filter((item) => item.name !== action.data.id)

            return {
                ...state,
                selectedShipment: {
                    ...state.selectedShipment,
                    pricingDetails: newPricingDetails
                }
            };

            case serviceOpsShipmentConstants.DELETE_ADDITIONAL_SERVICE_ROW:

                return {
                    ...state,
                    selectedShipment: {
                        ...state.selectedShipment,
                        [action.data.type]: false
                    }
                };

        default:
            return state
    }
}

function mapModelToTableRow(model) {

    const newObj = {
        id: model.id,
        trackingNumber: model.trackingNumber,
        pickupReference: model.pickupReference,
        pickupCountry: model.locations?.[0]?.country.Alpha2 || "",
        pickupZip: model.locations?.[0]?.zip || "",
        deliveryCountry: model.locations?.[1]?.country.Alpha2 || "",
        deliveryZip: model.locations?.[1]?.zip || "",
        carrier: model.carrier.label,
        bookedPrice: model.totalPrice,
        purchasePrice: model.purchasePrice,
        profit: model.purchasePrice > 0 ? (model.totalPrice - model.purchasePrice).toFixed(2) + ' €' : 'n/a',
        margin: model.purchasePrice > 0 ? (((model.totalPrice - model.purchasePrice) / model.totalPrice) * 100).toFixed(2) + ' %' : 'n/a',
        status: model.shipmentStatuses?.[0]?.status || "",
        statusTime: model.shipmentStatuses?.[0]?.time ? model.shipmentStatuses[0].time.split('T')[0] : ""
    };

    return newObj;
}
