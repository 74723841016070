import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SuiTypography from "_softUI/components/SuiTypography";
import SuiBox from "_softUI/components/SuiBox";
import Card from "@mui/material/Card";
import Grid from '@mui/material/Grid';
import { adminActions } from '_actions/admin.actions';
import { CarrierInput } from '_components/Admin/CarrierInput';
import { serviceOpsShipmentActions, serviceOpsActions } from '../../_actions';
import DataTable from '_components/Tables/DataTable';
import EditableDataTable from '_components/Tables/EditableTable';
import SuiButton from '_softUI/components/SuiButton';
import { useLocation } from "react-router-dom";
import { BookingsInput } from '_components/Admin/ServiceOps/BookingsInput';
import PriceEditCell from '_components/Tables/EditableTable/PriceEditCell';

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  
  function removeUrlParameter(paramKey) {
    const url = window.location.href
    console.log("url", url)
    var r = new URL(url)
    r.searchParams.delete(paramKey)
    const newUrl = r.href
    console.log("r.href", newUrl)
    window.history.pushState({ path: newUrl }, '', newUrl)
  }

export default function AdminBookingsTab() {
    const dispatch = useDispatch();
    const state = useSelector(state => state.serviceOpsShipment)
    const countries = useSelector(state => state.instantPricing).countries
    let query = useQuery();
    const [columnVisibility, setColumnVisibility] = useState({
      profit: false,
      margin: false,
      status: false,
      statusTime: false,
      trackingNumber: false,
      pickupReference: false,
    })

    useEffect(() => {
        dispatch(serviceOpsShipmentActions.initate(2)); 
    
        var selectedId = query.get('id')
    
        if(selectedId){
          dispatch(serviceOpsShipmentActions.getShipmentById(selectedId));
          removeUrlParameter("id")
        }
        if (countries.length === 0)
        {
          dispatch(serviceOpsShipmentActions.getAllCountries())
        }
      }, [])


    const carrierOptions = state.carrierOptions.map(carrier => carrier.name);
    const checkedRowActions = ['Follow up', 'Invoicing']

    const moveSelected = (selectedIds, action) => {
      if (action === 'Follow up'){
        dispatch(serviceOpsShipmentActions.moveSelectedToFollowUp(selectedIds, action))
        dispatch(serviceOpsActions.setTabValue(2))
      }
    }

    const addPricingDetail = (bookingId, name, price) => {
      dispatch(serviceOpsShipmentActions.addPricingDetail(bookingId, name, price))
    }
      
     const data = {
        columns: [
            { headerName: "ID", field: "id", width: 60 },
            { headerName: "Customer", field: "customer", width: 180 },
            { headerName: "Booking date", field: "bookedDate" },
            { headerName: "Tracking number", field: "trackingNumber", editable: true, width: 140 },
            { headerName: "Pickup ref", field: "pickupReference", editable: true},
            { headerName: "From", field: "pickupCountry", width: 20},
            { headerName: "Zip", field: "pickupZip", width: 70 },
            { headerName: "To", field: "deliveryCountry", width: 20 },
            { headerName: "Zip", field: "deliveryZip", width: 70 },
            { headerName: "Carrier", field: "carrier", editable: true, type: 'singleSelect', valueOptions: carrierOptions },
            { headerName: "Price", field: "bookedPrice", editable: true, renderEditCell: (params) => <PriceEditCell {...params} state={state} addPricingDetail={addPricingDetail} /> },
            { headerName: "Purchase", field: "purchasePrice", editable: true, width: 80},
            { headerName: "Profit", field: "profit",  width: 80},
            { headerName: "Margin", field: "margin",  width: 80},
            { headerName: "Status", field: "status", width: 150 },
            { headerName: "Status time", field: "statusTime", width: 100},
        ],
        rows: state.data.rows.map(x=> (
            {
              ...x,
              idHidden: x.id,
              bookingDate: x.bookedDate,
              id: x.id,
              idSearch: x.id + ", " + x.bookingDate,
              trackingNumber: x.trackingNumber,
              pickupReference: x.pickupReference,
              customer: x.customer,
              user: x.user,
              firstName: x.firstName ?? " ",
              lastName: x.lastName ? x.lastName.charAt(0) + "." : " " ,
              customerSearch: x.customer + " " + x.user,
              pickupCity: x.pickupCity,
              pickup: x.pickup,
              pickupCountry: x.pickupCountry,
              pickupCompany: x.pickupCompany,
              pickupSearch: x.pickupCity + " " + x.pickupCountry + " " + x.pickupCompany,
              deliveryCity:x.deliveryCity,
              delivery: x.delivery,
              deliveryCountry:x.deliveryCountry,
              deliveryCompany:x.deliveryCompany,
              deliverySearch: x.deliveryCity + " " + x.deliveryCountry + " " + x.deliveryCompany,
              carrierLogo: x.carrierLogo,
              carrier: x.carrier,
              carrierSearch: x.carrier,
              pickupReference:x.pickupReference,
              deliveryReference: x.deliveryReference,
              referenceSearch: x.pickupReference + " " + x.deliveryReference,
              bookedPrice: x.bookedPrice,
              bookedPriceSearch: x.bookedPrice,
              status: x.status,
              statusTime: new Date(x.statusTime).toLocaleDateString('en-GB'),
              state: x.state, 
              statusSearch: x.status,
              purchasePrice: x.purchasePrice,
              profit: x.purchasePrice > 0 ? (x.bookedPrice - x.purchasePrice).toFixed(2) + ' €' : 'n/a',
              margin: x.purchasePrice > 0 ? (((x.bookedPrice - x.purchasePrice) / x.bookedPrice) * 100).toFixed(2) + ' %' : 'n/a'
            }))
    }

    const columnVisibilityOptions = ([
      {id: 1, label: 'Show profit & margin',  columns: ['profit', 'margin']},
      {id: 2, label: 'Show status', columns: ['status', 'statusTime']},
      {id: 3, label: 'Show carrier IDs', columns: [
        'trackingNumber',
        'pickupReference',
      ]}
    ])

    const handleColumnVisibilityChange = (columns) => {
      setColumnVisibility((prevVisibility) => {
        const updatedVisibility = { ...prevVisibility };
  
        columns.forEach((column) => {
          updatedVisibility[column] = !prevVisibility[column];
        });
  
        return updatedVisibility;
      });
    };


    const openModal = (id) => {
        const purchasePrice = state.data.rows.find((x) => x.id === id).purchasePrice
        dispatch(serviceOpsShipmentActions.getShipmentById(id, purchasePrice));
      }

      const handleSaveRow = (newRow) => {
        const carrier = state.carrierOptions.find(c => c.name === newRow.carrier)
        newRow = {
          ...newRow,
          carrierId: carrier.id,
          statusTime: null
        }
        dispatch(serviceOpsShipmentActions.updateShipmentInformation(newRow));
      }

      const handleRowUpdate = (newRow) => {
        // dispatch(serviceOpsShipmentActions.updateShipmentInformation(newRow));
        // if()
      }

    const handleModalClose = () => {
        dispatch(serviceOpsShipmentActions.closeModal());
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start" 
            sx={{ height: '100%', width: '100%' }}>

            <Grid container item  xs={12} alignItems="stretch" justifyContent="center">
                <Grid item xs={12} md={12}>
                    <Card sx={{ overflow: "auto"}}>
                            <SuiBox>
                                <Grid container alignItems="strecth" justifyContent="center">
                                </Grid>


                                <SuiBox >
                                    <Grid container spacing={0}>
                                        <Grid item xs={12} sm={12}>
                                          {useMemo(() => (
                                            <EditableDataTable rows={data.rows} columns={data.columns} saveRow={handleSaveRow} updateRow={handleRowUpdate} openModal={openModal} columnVisibility={columnVisibility} columnVisibilityOptions={columnVisibilityOptions} columnVisibilityChange={handleColumnVisibilityChange} checkedRowActions={checkedRowActions} moveSelected={moveSelected} loading={state.loading}/>
                                          ), [data.rows, data.columns, state.loading, columnVisibility])}

                                        </Grid>
                                    </Grid>

                                </SuiBox>
                            </SuiBox>
                    </Card>
                </Grid>
            </Grid>
            <BookingsInput state={state} handleClose={handleModalClose} />
        </Grid>
    )
}
