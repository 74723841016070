import Grid from '@mui/material/Grid';
import { useEffect, useState } from "react";
import InputWithLabel from './../../FormFields/InputWithLabel'
import { DataGrid } from '@mui/x-data-grid';
import SuiTypography from "_softUI/components/SuiTypography";
import { TextField, Box, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SuiButton from "_softUI/components/SuiButton";
import { additionalServiceOptions } from '../../../_helpers/selections'
import SelectWithLabel from '../../FormFields/SelectWithLabel'
import SuiSelect from "_softUI/components/SuiSelect";
import WarningIcon from '@mui/icons-material/Warning';
import Label from '_components/FormFields/Label'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';


export function BillingInput({ state, purchasePrice, handleBasicInfoChange, addPricingDetail, saveBillingDetails, addAdditionalService, handleDeleteAdditionalService, handleDeletePricingDetail }) {

    const [additionalServiceToAdd, setAdditionalServiceToAdd] = useState('');

    const columns = [
        // { headerName: "ID", field: "id", width: 60 },
        { headerName: "Name", field: "name", flex: 2 },
        { headerName: "Value", field: "value", flex: 1 },
        {
            headerName: "Actions",
            field: "actions",
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <IconButton
                    color="error"
                    onClick={() => handleDeletePricingDetail(params.row.id, 'pricingDetails')}
                >
                    <DeleteIcon />
                </IconButton>
            ),
        },
    ];
    const rows = state.pricingDetails.map(x => (
        {
            ...x,
            id: x.name,
            name: x.name,
            value: x.value
        }))

    const pricingDetailsTotal = parseFloat(state.pricingDetails.reduce((sum, item) => sum + item.value, 0).toFixed(2));

    const additionalServices = {
        adr: state.adr,
        heatedTransport: state.heatedTransport,
        callOnDelivery: state.callOnDelivery,
        callOnPickup: state.callOnPickup,
        insurance: state.insurance,
        consumerDelivery: state.consumerDelivery,
        exportDeclaration: state.exportDeclaration,
        importDeclaration: state.importDeclaratin,
        temperatureControl: state.temperatureControl,
        tailLift: state.tailLift
    }

    const additionalServiceColumns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'label', headerName: 'Active Feature', flex: 2.5 },
        {
            headerName: "Actions",
            field: "actions",
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <IconButton
                    color="error"
                    onClick={() => handleDeleteAdditionalService(params.row.id, params.row.label)}
                >
                    <DeleteIcon />
                </IconButton>
            ),
        },
    ]

    const additionalServiceRows = Object.entries(additionalServices)
        .filter(([key, value]) => value)
        .map(([key], index) => ({
            id: index + 1,
            label: key,
        }));


    useEffect(() => {
        const totalDetails = rows.reduce((acc, row) => acc + row.value, 0)
        const priceDifference = Number(state.totalPrice - totalDetails).toFixed(2)
        handleBasicInfoChange({ target: { name: 'newPricingDetailValue', value: priceDifference } })
    }, [state.totalPrice])

    return (
        <Grid item xs={12} container spacing={2} justifyContent="space-evenly" alignItems="stretch" >
            <Grid item container xs={2}>
                <SuiTypography variant="h6" color="secondary" fontWeight="bold" textAlign="left" >
                    Billing
                </SuiTypography>
            </Grid>
            <Grid item xs={9.7} spacing={2}>
                <Grid container item spacing={1}>
                    <Grid item xs={4}>
                        <InputWithLabel
                            label="Sold price"
                            name="totalPrice"
                            onChange={e => handleBasicInfoChange(e)}
                            fullWidth
                            value={state.totalPrice}

                            adornment="€"
                            adornmentPosition="end"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <InputWithLabel
                            label="Purchase price"
                            name="purchasePrice"
                            onChange={e => handleBasicInfoChange(e)}
                            fullWidth
                            value={state.purchasePrice}

                            adornment="€"
                            adornmentPosition="end"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <InputWithLabel
                            label="Expected carrier price"
                            name="expectedCarrierPrice"
                            onChange={e => handleBasicInfoChange(e)}
                            fullWidth
                            disabled
                            value={state.expectedCarrierPrice}
                            adornment="€"
                            adornmentPosition="end"
                        />
                    </Grid>
                    {pricingDetailsTotal !== state.totalPrice &&
                    <Grid mt={0.5} xs={12}>
                          <Label icon={<WarningIcon onClick={e => handleBasicInfoChange({ target: { name: 'totalPrice', value: pricingDetailsTotal } })} sx={{ color: 'red', marginLeft: '5px', cursor: 'pointer' }} />} sx={{ color: '#494949', fontWeight: '300', fontSize: '0.75rem'}} value={
                              'Deviates from pricing details. Click icon to change to ' + pricingDetailsTotal + ' €'
                             } />
                             </Grid>
                            
                        }
                    {/* <Grid item xs={3} display='flex' justifyContent='center' alignItems='flex-end'>
                        <SuiButton variant="contained" color="secondary" onClick={saveBillingDetails}>
                            Save
                        </SuiButton>
                    </Grid> */}
                </Grid>
            </Grid>
            <Grid item container xs={2} mt={2}>
                <SuiTypography variant="h6" color="secondary" fontWeight="bold" textAlign="left" >
                    Additional Services
                </SuiTypography>
            </Grid>
            <Grid item xs={9.7} spacing={1} mt={2}>
                <DataGrid
                    hideFooter
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            display: 'none', // Hide the header
                        },
                    }}
                    rows={additionalServiceRows}
                    columns={additionalServiceColumns}
                    pageSize={5}
                    disableRowSelectionOnClick
                    autoHeight
                />


                <Grid item container>
                    <Grid container xs={12}>
                        <Grid item xs={7} pr={1}>
                            <SuiSelect
                                sx={{ width: '100%' }}
                                options={additionalServiceOptions}
                                placeholder='Select additional service to add'
                                name='newPricingDetailName'
                                value={state.newAdditionalService}
                                onChange={e => handleBasicInfoChange({ target: { name: 'newAdditionalService', value: { value: e.value, label: e.label } } })}
                            />
                        </Grid>
                        <Grid item xs={4.5} pr={1}>
                            <TextField
                                sx={{ width: '100%' }}
                                placeholder="€"
                                name="newAdditionalServiceValue"
                                onChange={e => handleBasicInfoChange(e)}
                                fullWidth
                                value={state.newAdditionalServiceValue}
                                adornment="€"
                                adornmentPosition="end"
                            />
                        </Grid>
                        <Grid item xs={0.5} display="flex" justifyContent="flex-end">
                            <AddIcon fontSize='large' color="primary" sx={{ cursor: 'pointer' }}
                                onClick={() => addAdditionalService(state.newAdditionalService, state.newAdditionalServiceValue)}
                            />
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item container xs={2} mt={2}>
                <SuiTypography variant="h6" color="secondary" fontWeight="bold" textAlign="left" >
                    Pricing Details
                </SuiTypography>
            </Grid>

            <Grid item xs={9.7} spacing={1} mt={2} sx={{ height: '100%' }}>
                <DataGrid
                    autoHeight
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            display: 'none', // Hide the header
                        },
                    }}
                    pageSizeOptions={[10]}
                    disableRowSelectionOnClick
                />


                <Grid item container>
                    <Grid container xs={12}>
                        <Grid item xs={7} pr={1}>
                            <TextField
                                sx={{ width: '100%' }}
                                placeholder='Type detail name to add'
                                name='newPricingDetailName'
                                value={state.newPricingDetailName}
                                onChange={e => handleBasicInfoChange(e)}
                            />
                        </Grid>
                        <Grid item xs={4.5} pr={1}>
                            <TextField
                                sx={{ width: '100%' }}
                                name='newPricingDetailValue'
                                onChange={e => handleBasicInfoChange(e)}
                                value={state.newPricingDetailValue}
                                placeholder="€"
                            />
                        </Grid>
                        <Grid item xs={0.5} display="flex" justifyContent="flex-end">
                            <AddIcon fontSize='large' color="primary" sx={{ cursor: 'pointer' }}
                                onClick={() => addPricingDetail(state.newPricingDetailName, state.newPricingDetailValue)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}