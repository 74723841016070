import React from 'react';

import Grid from '@mui/material/Grid';

import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import { SearchLocation } from './SearchLocation'
import { InputDetailsContainer } from './InputDetailsContainer'
import { mapInstantPricingRequestDTO } from '../../_helpers';
import { alertActions, instantPricingActions } from '../../_actions';

import SuiButton from '_softUI/components/SuiButton'

import Typography from '@mui/material/Typography';
import ReactCountryFlag from "react-country-flag"
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';




import { useEffect } from 'react';

export function SearchContainer({ title, index }) {
  const dispatch = useDispatch()
  const state = useSelector(state => state.instantPricing)

  const declarationsNeeded = ['NO', 'CH', 'GB']

  const offerDeclaration = declarationsNeeded.includes(state.originCountry.value) || declarationsNeeded.includes(state.destinationCountry.value)

  console.log('validiaa', state.valid)
  function getPricesClick() {
    if (state.valid && !state.showError) {
      dispatch(instantPricingActions.getPrice(mapInstantPricingRequestDTO(state, 'getPricesClick')))
    } else if (state.showError) {
      dispatch(alertActions.error(state.errorMessage))
      dispatch(instantPricingActions.showHints())
    } else {
      dispatch(instantPricingActions.showHints())
      dispatch(alertActions.error('Please fill all the required fields!'))
    }

  }

  function addGoodsLine() {
    dispatch(instantPricingActions.addDetailRow());
  }

  function handleChange(event) {
    dispatch(instantPricingActions.updateValue(event.target.name, event.target.value));
  }

  function handleSwitchChange(name, value) {
    console.log(value)
    dispatch(instantPricingActions.showAdditionalServices(name, value));

    if(!value) {
      dispatch(instantPricingActions.clearAdditionalServices())
    }
  }


  useEffect(() => {
    
    if (state.originCountry.value !== "FI" && state.originCountry.value !== "SE" && state.destinationCountry.value !== "FI" && state.destinationCountry.value !== "SE") {
      const user = JSON.parse(localStorage.getItem('user'));


      dispatch(instantPricingActions.changeCountry({
        label: <React.Fragment><span><ReactCountryFlag svg   countryCode={user.country} width="20" /></span><span style={{ marginLeft: '10px' }}>{state.countries.find((item) => item.alpha2 === user.country)?.name}</span></React.Fragment>
        , value: user.country
      }, 'destination'))
      





    }
    
    
  }, [state.originCountry])

  useEffect(() => {
  
    if (state.originCountry.value !== "FI" && state.originCountry.value !== "SE" && state.destinationCountry.value !== "FI" && state.destinationCountry.value !== "SE") {
      const user = JSON.parse(localStorage.getItem('user'));

      dispatch(instantPricingActions.changeCountry({ label: <React.Fragment><span><ReactCountryFlag svg   countryCode={user.country} width="20" /></span><span style={{ marginLeft: '10px' }}>{state.countries.find((item) => item.alpha2 === user.country)?.name}</span></React.Fragment>, value: user.country }, 'origin'))


   
    }
    



  }, [state.destinationCountry])






  return (

    <Container sx={{ overflow: 'unset', '.MuiPaper-root': { overflow: 'unset' }, justifyContent: 'center' }}>
 
      <Grid item container xs={12} sx={{}}>

   
        <Grid container spacing={2} direction="row" alignItems="start" justifyContent="flex-start">
          <Grid item lg={6} md={6} xs={12} mb={1}>
            <SearchLocation title="origin"
              selectedCountry={state.originCountry}
              selectedLocation={state.originZip}
              locationOptions={state.originZipOptions}
              hint={state.originZipHint}
              zipExample={state.originZipExample} />
          </Grid>
          <Grid item lg={6} md={6} xs={12} mb={1}>
            <SearchLocation title="destination"
              selectedCountry={state.destinationCountry}
              selectedLocation={state.destinationZip}
              locationOptions={state.destinationZipOptions}
              hint={state.destinationZipHint}
              zipExample={state.destinationZipExample} />
          </Grid>

        </Grid>
        <Grid container direction="row" alignItems="center" justifyContent="center"  >
          <Grid item lg={12} md={12} xs={12} >
            {/* <Collapse in={true}> */}

            <InputDetailsContainer />


            {/* </Collapse> */}
          </Grid>

          <Grid container direction="row" alignItems="start" justifyContent="space-between"  >
            <Grid item xs={12} md={2}>
              <SuiButton variant="outlined" sx={{width:'100%'}} color="primary" size="small" onClick={addGoodsLine} >+ Add row</SuiButton>
            </Grid>
            <Grid item xs={12} md={4}>
            {offerDeclaration &&
              <Typography variant='subtitle2' sx={{ color: 'red', fontSize: '0.6em' }} >
                If you need assistance with customs clearances please select desired options in the addtional services section!
              </Typography>
}
            </Grid>
            <Grid container item xs={12} md={5} direction="row" alignItems="center" justifyContent="end">


              <Grid item xs={12} md={8}>


                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant='subtitle2' sx={{ pr: 1, color: 'text.secondary' }} >
                    Additional services:
                  </Typography>

                  <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>No</Typography>
                  <Switch
                    size={'small'}
                    checked={state.showAdditionalServices}
                    onChange={e => handleSwitchChange('showAdditionalServices', e.target.checked)}

                  />

                  <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>Yes</Typography>
                </Stack>
                <Typography variant='subtitle2' sx={{ 'fontSize': '0.5em', color: 'text.secondary' }}>Services: insurance, ADR, notification call, tail lift</Typography>
              </Grid>

              <Grid item lg={4} xs={12} container justifyContent="right">
                <SuiButton variant="contained" sx={{width:'100%'}} color="secondary" onClick={getPricesClick} >Get prices</SuiButton>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>




    </Container>




  )
}