import React, { useCallback } from 'react';
import { useState } from "react";
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputWithLabel from '_components/FormFields/InputWithLabel';
import SelectWithLabel from '_components/FormFields/SelectWithLabel';
import LocationInputForm from '../../Common/LocationInputForm'
import { GoodsRow } from '../../Common/GoodsRow'
import { useDispatch, useSelector } from 'react-redux';
import ReactCountryFlag from "react-country-flag"
import Divider from '@mui/material/Divider';
import { goodsRowModel } from '_reducers/models';
import SuiTypography from "_softUI/components/SuiTypography";
import { serviceOpsShipmentActions } from '../../../_actions';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SuiButton from "_softUI/components/SuiButton";
import { BasicInfo } from '../../ShipmentInformationForm/BasicInfo'
import { BillingInput } from './BillingInput'
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import BeenhereRoundedIcon from '@mui/icons-material/BeenhereRounded';
import EuroIcon from '@mui/icons-material/Euro';
import QueueIcon from '@mui/icons-material/Queue';
import Tracking from '../../ShipmentDisplayModal/Tracking';
import { Loading, Success } from '../../Shared'


export function BookingsInput({ state, handleClose }) {

    const [tabValue, setTabValue] = useState(0);

    const countries = useSelector(state => state.instantPricing).countries;
    const goodsState = useSelector(state => state.ShipmentInformationForm.data)

    const row = state.data.rows.find((row) => row.id === state.selectedShipment.Id)
    const purchasePrice = row ? row.purchasePrice : null

    const carrierOptions = state.carrierOptions.map(carrier => ({
        value: carrier.id,
        label: carrier.name,
    }));


    const dispatch = useDispatch();
    const handleSetTabValue = (event, newValue) => setTabValue(newValue);

    const countryOptions = countries.map((x, index) =>
        ({ value: x.alpha2, label: <React.Fragment key={x.name} ><span><ReactCountryFlag svg countryCode={x.alpha2} width="20" /></span><span style={{ marginLeft: '10px' }}>{x.name}</span></React.Fragment> }))

    // function handleBookingInputChange(event) {
    //     dispatch(serviceOpsShipmentActions.updateBookingInputValue(event.target.name, event.target.value));
    // }

    function handleBasicInfoChange(event) {
        dispatch(serviceOpsShipmentActions.updateBasicValue(event.target.name, event.target.value))
    }

    function handleGoodsRowChange(event, index) {
        dispatch(serviceOpsShipmentActions.updateGoodsLine(event.target.name, event.target.value, index));
    }

    function handleLocationChange(event, index) {
        dispatch(serviceOpsShipmentActions.updateLocationValue(event.target.name, event.target.value, index));
    }

    function addGoodsLine() {
        dispatch(serviceOpsShipmentActions.addGoodsLine());
    }

    function removeGoodsLine(index) {
        dispatch(serviceOpsShipmentActions.removeGoodsLine(index));
    }



    function saveBillingDetails() {
        const billingInformation = {
            shipmentId: state.selectedShipment.id,
            price: state.selectedShipment.purchasePrice,
            priceType: 2,
            productCode: 0,
        }
        dispatch(serviceOpsShipmentActions.saveBillingDetails(state.selectedShipment, billingInformation));
    }

    function addPricingDetail(pricingDetailName, pricingDetailPrice) {
        dispatch(serviceOpsShipmentActions.addPricingDetailToState(pricingDetailName, pricingDetailPrice));
    }

    function addAdditionalService(additionalService, additionalServiceValue) {
        dispatch(serviceOpsShipmentActions.addAdditionalService(additionalService.value, additionalService.label, additionalServiceValue));
    }

    function handleDeleteAdditionalService(id, type) {
        dispatch(serviceOpsShipmentActions.deleteAdditionalServiceRow(id, type));
    }

    function handleDeletePricingDetail(id, type) {
        dispatch(serviceOpsShipmentActions.deletePricingDetailRow(id, type));
    }


    function handleCopyContentClick(index) {
        const location = state.selectedShipment.locations[index]
        const stringToCopy = location.name + '\n' + location.streetAddress + '\n' + location.zip + ', ' + location.city + '\n' + location.country + '\n\n' + location.contactName + '\n' + location.contactPhone + '\n' + location.contactEmail
        navigator.clipboard.writeText(stringToCopy)
    }

    function handleBookingUpdate() {

        const model = {
            ...state.selectedShipment,
            selectedCarrier: state.selectedShipment.carrier.label,
            selectedCarrierId: state.selectedShipment.carrier.value,
            locations: state.selectedShipment.locations.map(location => ({
                ...location,
                country: {
                    Id: location.countryId,
                    Name: location.country,
                    Alpha2: location.countryAlpha2
                }
            }))
        }
        dispatch(serviceOpsShipmentActions.updateShipment(model))
        handleClose()
    }


    return (
        <React.Fragment>

            <Dialog
                fullWidth={true}
                open={state.modalOpen}
                // fullWidth={true}
                maxWidth='xl'


                // open={state.modalOpen}
                onClose={() => handleClose}
                aria-labelledby="max-width-dialog-title"
            // scroll='body'
            >
                {state.modalLoading && <Loading text="Loading" size="h5"></Loading>}
                {!state.modalLoading &&
                    <>
                        <DialogTitle>ID {state.selectedShipment.id}</DialogTitle>
                        <DialogContent sx={{ height: '900px', overflow: 'auto', paddingRight: '2rem', paddingLeft: '2rem' }}>
                            <Divider sx={{ m: 0, width: '100%' }} />
                            <Grid container justifyContent="flex-end" sx={{ backgroundColor: '#fff' }}>

                                <Grid item xs={12}>
                                    <Tabs
                                        orientation='horizontal'
                                        value={tabValue}

                                        onChange={handleSetTabValue}
                                        sx={{ background: "transparent" }}
                                    >
                                        <Tab label="Booking" icon={<BeenhereRoundedIcon sx={{ color: "#A7936A" }} />} value={0} />
                                        <Tab label="Track & trace" icon={<PlaceRoundedIcon sx={{ color: "#A7936A" }} />} value={1} />
                                        <Tab label="Billing" icon={<EuroIcon sx={{ color: "#A7936A" }} />} value={2} />
                                    </Tabs>
                                </Grid>
                            </Grid>
                            <Divider sx={{ m: 0 }} />
                            {tabValue === 0 && (
                                <>
                                    <Grid item container xs={12} spacing={2} mt={2}>
                                        <Grid item container xs={2}>
                                            <SuiTypography variant="h6" color="secondary" fontWeight="bold" textAlign="left" >
                                                Details
                                            </SuiTypography>
                                        </Grid>
                                        <Grid container item xs={9.7} spacing={2}>
                                            <Grid item container xs={12} spacing={1}>
                                                <Grid item xs={4}>
                                                    <SelectWithLabel
                                                        label="Carrier"
                                                        name="carrier"
                                                        //defaultValue={state.selectedShipment.selectedCarrier}
                                                        onChange={e => handleBasicInfoChange({ target: { name: 'carrier', value: { value: e.value, label: e.label } } })}
                                                        fullWidth
                                                        value={state.selectedShipment.carrier}
                                                        // value={{ value: state.selectedShipment.selectedCarrierId, label: state.selectedShipment.selectedCarrier }}
                                                        type="date"
                                                        mandatory={true}
                                                        options={carrierOptions}
                                                    // error={state.pickupStartError}
                                                    />
                                                </Grid>
                                                {/* <Grid container item xs={9} pl={1} spacing={1}> */}
                                                {/* <Grid item xs={4}>
                                                        <InputWithLabel
                                                            label="Goods ready for pickup"
                                                            name="pickupStart"
                                                            onChange={e => handleBasicInfoChange(e)}
                                                            fullWidth
                                                            value={state.selectedShipment.pickupStart}
                                                            type="date"
                                                            mandatory={true}
                                                            error={state.pickupStartError}
                                                        />
                                                    </Grid> */}
                                                <Grid item xs={4}>
                                                    <InputWithLabel
                                                        label="Tracking Number"
                                                        name="trackingNumber"
                                                        onChange={e => handleBasicInfoChange(e, "trackingNumber", 35)}
                                                        fullWidth
                                                        value={state.selectedShipment.trackingNumber}
                                                        //maxlenght={35}
                                                        adornment="#"
                                                        adornmentPosition="end"
                                                        placeholder="carrier tracking number"

                                                    />

                                                </Grid>
                                                <Grid item xs={4}>
                                                    <InputWithLabel
                                                        label="Pickup reference"
                                                        name="pickupReference"
                                                        onChange={e => handleBasicInfoChange(e, "pickupReference", 35)}
                                                        fullWidth
                                                        value={state.selectedShipment.pickupReference}

                                                        adornment="#"
                                                        adornmentPosition="end"
                                                        placeholder="carrier pickup reference"
                                                    />
                                                </Grid>
                                                {/* </Grid> */}
                                            </Grid>
                                            <Grid item container xs={12} spacing={1}>
                                                <Grid item xs={4}>
                                                    <InputWithLabel
                                                        label="Goods ready for pickup"
                                                        name="pickupStart"
                                                        onChange={e => handleBasicInfoChange(e)}
                                                        fullWidth
                                                        value={state.selectedShipment.pickupStart}
                                                        type="date"
                                                        mandatory={true}

                                                    // error={state.pickupStartError}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <InputWithLabel
                                                        label="Sender reference"
                                                        name="senderReference"
                                                        onChange={e => handleBasicInfoChange(e, "Sender reference", 35)}
                                                        fullWidth
                                                        value={state.selectedShipment.senderReference}
                                                        //maxlenght={35}
                                                        adornment="#"
                                                        adornmentPosition="end"
                                                        placeholder="reference"

                                                    />

                                                </Grid>
                                                <Grid item xs={4}>
                                                    <InputWithLabel
                                                        label="Receiver reference"
                                                        name="receiverReference"
                                                        onChange={e => handleBasicInfoChange(e, "Receiver reference", 35)}
                                                        fullWidth
                                                        value={state.selectedShipment.receiverReference}

                                                        adornment="#"
                                                        adornmentPosition="end"
                                                        placeholder="reference"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ mt: 2, mb: 2 }} />
                                    <Grid
                                        display="flex"
                                        alignItems="center"
                                        position="relative"
                                        xs={12}
                                        container
                                        item
                                        mb={2}
                                        sx={{
                                            background: '#fff',
                                            width: '100%',
                                        }}
                                    >
                                        <Grid item container xs={12} spacing={2}  >
                                            <Grid item container xs={2} >
                                                <SuiTypography variant="h6" color="secondary" fontWeight="bold" textAlign="left" >
                                                    Pickup

                                                </SuiTypography>



                                            </Grid>
                                            <Grid container item xs={9.7} spacing={2}>
                                                <LocationInputForm
                                                    title="Pickup"
                                                    index={0}
                                                    handleInputChange={e => handleLocationChange(e, 0)}
                                                    // handleCountryChange={e => handleCountryChange('countryAlpha2', e.value, 0)}
                                                    location={state.selectedShipment.locations[0]}
                                                    countryOptions={countryOptions}
                                                    // locationOptions={savedLocationList}        
                                                    instant={false}
                                                    admin={true}
                                                    // loadSavedLocation={loadSavedLocation}
                                                    handleLocationChangeWithMaxLenght={e => handleLocationChange(e, 0)}
                                                // addressbook={true}
                                                />
                                            </Grid>
                                            <Grid container item xs={0.3}>
                                                <ContentCopyIcon color='secondary' sx={{ cursor: 'pointer' }} onClick={() => handleCopyContentClick(0)} />
                                            </Grid>


                                        </Grid>

                                    </Grid>

                                    <Divider sx={{ mt: 2, mb: 2 }} />
                                    <Grid
                                        display="flex"
                                        alignItems="center"
                                        position="relative"
                                        xs={12}
                                        container
                                        item
                                        mb={2}
                                        sx={{
                                            background: '#fff',
                                            width: '100%',
                                        }}
                                    >
                                        <Grid item container xs={12} spacing={2}  >
                                            <Grid item container xs={2}>
                                                <SuiTypography variant="h6" color="secondary" fontWeight="bold" textAlign="left" >
                                                    Delivery
                                                </SuiTypography>
                                            </Grid>
                                            <Grid container item xs={9.7} spacing={2}>
                                                <LocationInputForm
                                                    title="Delivery"
                                                    index={1}
                                                    handleInputChange={e => handleLocationChange(e, 1)}
                                                    // handleCountryChange={e => handleCountryChange('countryAlpha2', e.value, 1)}
                                                    location={state.selectedShipment.locations[1]}
                                                    countryOptions={countryOptions}
                                                    // locationOptions={savedLocationList}        
                                                    instant={false}
                                                    admin={true}
                                                    // instant={instant}
                                                    // loadSavedLocation={loadSavedLocation}
                                                    handleLocationChangeWithMaxLenght={e => handleLocationChange(e, 1)}
                                                // addressbook={true}
                                                />
                                            </Grid>
                                            <Grid container item xs={0.3}>
                                                <ContentCopyIcon color='secondary' sx={{ cursor: 'pointer' }} onClick={() => handleCopyContentClick(1)} />
                                            </Grid>


                                        </Grid>

                                    </Grid>
                                    <Divider sx={{ mt: 2, mb: 2 }} />
                                    <Grid
                                        display="flex"
                                        alignItems="center"
                                        position="relative"
                                        xs={12}
                                        container
                                        item
                                        mb={2}
                                        sx={{
                                            background: '#fff',
                                            width: '100%',
                                        }}
                                    >
                                        <Grid item container xs={12} spacing={2}  >
                                            <Grid item container xs={2}>
                                                <SuiTypography variant="h6" color="secondary" fontWeight="bold" textAlign="left" >
                                                    Goods
                                                </SuiTypography>
                                            </Grid>
                                            <Grid container item xs={9.7} >
                                                {state.selectedShipment.goods.map((x, index) =>
                                                    <GoodsRow
                                                        key={0}
                                                        index={index}
                                                        handleRowChange={handleGoodsRowChange}
                                                        handleRowChangeWithMaxLenght={handleGoodsRowChange}
                                                        removeGoodsLine={removeGoodsLine}
                                                        // handleStackableChange={handleStackableChange}

                                                        // calculating={calculating}
                                                        goods={state.selectedShipment.goods}
                                                        showDescription={true}
                                                        // refreshPriceOnChange={true}
                                                        state={goodsState}
                                                        shipmentFormType={'admin'}
                                                    />)}
                                            </Grid>
                                        </Grid>

                                        <Grid item container xs={12} spacing={2}>
                                            <Grid item container xs={2}></Grid>
                                            <Grid item xs={6} >
                                                <SuiButton variant="outlined" size="small" color="primary" onClick={addGoodsLine}>+ Cargo item row</SuiButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ mt: 2, mb: 2 }} />
                                    <Grid
                                        display="flex"
                                        alignItems="center"
                                        position="relative"
                                        xs={12}
                                        container
                                        item
                                        mb={2}
                                        sx={{
                                            background: '#fff',
                                            width: '100%',
                                        }}
                                    >
                                    </Grid>
                                    <Grid
                                        container
                                        justifyContent="flex-end"
                                        alignItems="flex-end"
                                        spacing={2}
                                    >
                                        <Grid item >
                                            <SuiButton onClick={handleBookingUpdate} variant="contained" color="secondary">
                                                Update
                                            </SuiButton>

                                        </Grid>
                                        <Grid item >
                                            <SuiButton onClick={handleClose} variant="contained" color="secondary">
                                                Close
                                            </SuiButton>

                                        </Grid>
                                    </Grid>
                                </>)
                            }
                            {tabValue === 1 &&
                                <>
                                    <Tracking state={state.selectedShipment.shipmentStatuses} trackingUrl={state.selectedShipment.trackingUrl} />
                                    <Divider sx={{ mt: 2, mb: 2 }} />
                                    <Grid
                                        display="flex"
                                        alignItems="center"
                                        position="relative"
                                        xs={12}
                                        container
                                        item
                                        mb={2}
                                        sx={{
                                            background: '#fff',
                                            width: '100%',
                                        }}
                                    >
                                    </Grid>
                                    <Grid
                                        container
                                        justifyContent="flex-end"
                                        alignItems="flex-end"
                                    >
                                        <Grid item >
                                            <SuiButton onClick={handleClose} variant="contained" color="secondary">
                                                Close
                                            </SuiButton>

                                        </Grid>
                                    </Grid>
                                </>
                            }
                            {tabValue === 2 &&
                                <>
                                    <Grid item container xs={12} spacing={2} mt={2} >
                                        {/* <Grid item container xs={2}>
                                            <SuiTypography variant="h6" color="secondary" fontWeight="bold" textAlign="left" >
                                                Billing
                                            </SuiTypography>
                                        </Grid> */}
                                        {/* <Grid container item xs={9.7} spacing={2}> */}
                                        <BillingInput state={state.selectedShipment} purchasePrice={purchasePrice} handleBasicInfoChange={handleBasicInfoChange} addPricingDetail={addPricingDetail} saveBillingDetails={saveBillingDetails} addAdditionalService={addAdditionalService} handleDeletePricingDetail={handleDeletePricingDetail} handleDeleteAdditionalService={handleDeleteAdditionalService}/>
                                        {/* </Grid> */}
                                    </Grid>
                                    <Divider sx={{ mt: 2, mb: 2 }} />
                                    <Grid
                                        display="flex"
                                        alignItems="center"
                                        position="relative"
                                        xs={12}
                                        container
                                        item
                                        mb={2}
                                        sx={{
                                            background: '#fff',
                                            width: '100%',
                                        }}
                                    >
                                    </Grid>
                                    <Grid
                                        container
                                        justifyContent="flex-end"
                                        alignItems="flex-end"
                                    >
                                        <Grid item xs={3} display='flex' justifyContent='center' alignItems='flex-end'>
                                            <SuiButton variant="contained" color="secondary" onClick={saveBillingDetails}>
                                                Save
                                            </SuiButton>
                                        </Grid>
                                        <Grid item >
                                            <SuiButton onClick={handleClose} variant="contained" color="secondary">
                                                Close
                                            </SuiButton>

                                        </Grid>
                                    </Grid>
                                </>
                            }

                        </DialogContent>
                        <DialogActions></DialogActions>
                    </>
                }


            </Dialog>

        </React.Fragment>
    );

}





