import React from 'react';
import {
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { Box, Button } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SuiButton from '_softUI/components/SuiButton';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const CustomToolbar = ({ columnVisibility, columnVisibilityOptions, columnVisibilityChange, checkedRowActions, selectionModel, moveSelected, tableSwitchButton }) => {

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '8px' }}>
        <Box display='flex' alignItems='center'>
            {tableSwitchButton}
          <FormGroup row >
            {columnVisibilityOptions.map((option) => (
              <FormControlLabel
                key={option.id}
                control={
                  <Checkbox
                    checked={option.columns.every((column) => columnVisibility[column])}
                    onChange={() => columnVisibilityChange(option.columns)}
                  />
                }
                label={option.label}
              />
            ))}
          </FormGroup>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Box display='flex' alignItems='center'>
            <GridToolbarDensitySelector />
            <GridToolbarExport />
          </Box>
          <Box >
            {/* Quick Filter */}
            <GridToolbarQuickFilter />
          </Box>
        </Box>
      </Box>
      
      {selectionModel.length > 0 && (
        <Box sx={{ 
          display: 'flex', 
          justifyContent: checkedRowActions[0] === "Mark completed" || checkedRowActions[0] === "Add prices to shipments"  ? 'flex-end' : 'flex-start' 
        }}>
      {checkedRowActions.map((action) => (
        <SuiButton onClick={() => {moveSelected(selectionModel, action)}} variant="outlined" size="small" color="primary" sx={{marginLeft: '8px'}}>
          <KeyboardDoubleArrowRightIcon size="large"  />{action}
        </SuiButton>
      ))}
      </Box>
  )}
    </>
  );
};

export default CustomToolbar;