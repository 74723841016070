import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { registerActions, userActions } from '../_actions';
import { Grid } from '@mui/material';
import logo from "_assets/images/newLogo.jpg";
import { useParams, useLocation } from "react-router-dom";
import bgBlue from "_assets/images/bgBlue2.png";
import bgGray from "_assets/images/bgGray.png";
import logoBig from "_assets/images/logoBig.png";
import demo1 from "_assets/images/demo1.png";
import SuiBox from "_softUI/components/SuiBox";
import SuiButton from '_softUI/components/SuiButton';
import SuiTypography from "_softUI/components/SuiTypography";
import Register from '_components/Register';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Login from '_components/Login';
import { Loading } from '_components';
import { Success } from '_components';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function ConfirmEmail() {
    const dispatch = useDispatch();
    const state = useSelector(state => state.authentication);
    let query = useQuery();

    useEffect(() => {
        const model = {
            userId: query.get('userId'),
            token: query.get('token')
        }
        dispatch(registerActions.confirmEmail(model));

    }, [])



    return (
        <>
            <Grid containerjustifyContent="center"
            alignItems="center" textAlign="center">
                {state.loading && <Loading />}
                {!state.loading && state.confirmed &&
                    <Grid item xs={12}>
                        <Success title="Email verified" />
                        <SuiTypography>  Your email is now verified. Our sales team will contact you shortly!</SuiTypography>
                    
                    </Grid>


                }

                {!state.loading && !state.confirmed && state.errorMessage &&
                    <Grid item>

                        Something went wrong. Please contact info@freightopt.com

                    </Grid>


                }



            </Grid>

        </>
    );

}


