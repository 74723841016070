import { useState } from "react";
import { useDispatch} from 'react-redux';
import Grid from "@mui/material/Grid";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { Paper } from "@mui/material";
import { instantPricingActions } from '../../_actions';
import FormControl from '@mui/material/FormControl';
import SettingsIcon from '@mui/icons-material/Settings';

export function InstantPricingContainer(props) {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('user'));


  
  const handleChange = (event) => {
    console.log(event.target.value)
    localStorage.setItem('user',JSON.stringify({...user,'defaultDirection':event.target.value}) )
    const previousValue = user.defaultDirection;

    //näissä vielä jotain vikaa
    if(event.target.value !== "Domestic"){
      if(previousValue ==="Domestic"){
        if(event.target.value === "Export"){
          dispatch(instantPricingActions.initate('',user?.country ?? 'FI','AT',''));
        }
        else{
     
          dispatch(instantPricingActions.initate('','AT',user?.country ?? 'FI',''));
        }
      }
      else{

        dispatch(instantPricingActions.flipLocations());
      }

    }
    else{

      dispatch(instantPricingActions.initate('',user?.country ?? 'FI',user?.country ?? 'FI',''));
    }

    dispatch(instantPricingActions.saveDefaultDirection(event.target.value))

  };


  return (
   
    <Paper elevation={3}>
      <Grid container display="flex" direction="column" alignItems="stretch" alignContent="center" sx={{}}  >
        <Grid container sx={{}}>
  
        <Grid  container item sx={{width:'100%', borderBottom:'1px solid #eeeeee',paddingLeft:'1.5rem'}} direction="row" alignItems="center" justifyContent="flex-start">
        {/* <SettingsIcon sx={{marginRight:'0.5rem', height:'2rem', marginLeft:'-0.5rem', color:'red'}}/> */}
        <FormControl>

      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        sx={{marginLeft:'0.25rem'}}
        value={user.defaultDirection}
        onChange={(e) =>handleChange(e)}
      >
            
 
        <FormControlLabel  value="Export" sx={{color:'#969696'}} control={<Radio  size="small" sx={{padding:'4px', color:'#9f9f9f'}} />} label="Export" />
        <FormControlLabel value="Import" sx={{color:'#969696'}} control={<Radio  size="small" sx={{padding:'4px', color:'#9f9f9f'}} />} label="Import" />
        <FormControlLabel value="Domestic" sx={{color:'#969696'}} control={<Radio  size="small" sx={{padding:'4px', color:'#9f9f9f'}} />} label="Domestic" />

      </RadioGroup>
      </FormControl>
      </Grid>
          <Grid item p="1rem" pt='0.25rem' mb="1rem" xs={12}  >

            <Grid container spacing={0} flexDirection="column" alignItems="center" >


              {props.children}

            </Grid>


          </Grid>


        </Grid>


      </Grid>
    </Paper>
    //   </SuiBox>

    // </SuiBox>
  );
}


