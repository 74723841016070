import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SuiBox from "_softUI/components/SuiBox";
import Card from "@mui/material/Card";
import Grid from '@mui/material/Grid';
import { serviceOpsFollowupActions, serviceOpsShipmentActions, serviceOpsTasksActions } from '../../_actions';
import EditableDataTable from '_components/Tables/EditableTable';
import { useLocation } from "react-router-dom";
import { BookingsInput } from '_components/Admin/ServiceOps/BookingsInput';
import PriceEditCell from '_components/Tables/EditableTable/PriceEditCell';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import ShipmentCommentsInput from '../../_components/Admin/ServiceOps/ShipmentCommentsInput'
import CustomToolbar from '../../_components/Tables/EditableTable/CustomToolbar';
import SuiButton from '_softUI/components/SuiButton';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import UndoIcon from '@mui/icons-material/Undo';
import notificationSound from '_assets/sounds/notification.wav'


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function removeUrlParameter(paramKey) {
  const url = window.location.href
  console.log("url", url)
  var r = new URL(url)
  r.searchParams.delete(paramKey)
  const newUrl = r.href
  console.log("r.href", newUrl)
  window.history.pushState({ path: newUrl }, '', newUrl)
}

export default function AdminTasksTab() {
  const dispatch = useDispatch();
  const state = useSelector(state => state.serviceOpsTasks)
  const followupState = useSelector(state => state.serviceOpsFollowup)


  let query = useQuery();
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedId, setSelectedId] = useState()
  const [selectionModel, setSelectionModel] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({
    completedUserId: false,
  })
  const [showCompleted, setShowCompleted] = useState(false)

  const checkedRowActions = ['Mark completed']
  const buttonText = showCompleted ? "Back to incomplete tasks" : "Show completed tasks"


  useEffect(() => {

    dispatch(serviceOpsTasksActions.initate(false));

  }, [])

  useEffect(() => {
    const runEveryMinute = () => {
      const taskIds = state.data.rows.map(task => task.id);
      dispatch(serviceOpsTasksActions.checkForChanges(false, taskIds));
    };

    if (state.viewState === 'incomplete') {
      const intervalId = setInterval(runEveryMinute, 60000);
      return () => clearInterval(intervalId);
    }
  }, [state.data.rows]);

  useEffect(() => {
    if (state.changeTrigger) {
      const audio = new Audio(notificationSound)
      audio.volume = 0.5
      audio.play()
      dispatch(serviceOpsTasksActions.updateChangeTrigger(false))
    }
  }, [state.changeTrigger])



  const data = {
    columns: [
      { headerName: "ID", field: "id", width: 60 },
      { headerName: "Description", field: "description", width: 650 },
      { headerName: "Shipment Id", field: "shipmentId", width: 100 },
      { headerName: "Created date", field: "creationDate", width: 100 },
      { headerName: "Created time", field: "creationTime", width: 100 },
      { headerName: "Completed By userId", field: "completedUserId", width: 10 },
      {
        headerName: '',
        align: 'center',
        field: 'select',
        width: 100,
        renderCell: (params) => (
          <>
            {!showCompleted && (
              <Checkbox
                checked={selectionModel.includes(params.id)}
                onChange={(event) => {
                  const newSelection = event.target.checked
                    ? [...selectionModel, params.id]
                    : selectionModel.filter(id => id !== params.id);
                  setSelectionModel(newSelection);
                }}
              />
            )}
            {showCompleted && (
              <>
                <UndoIcon fontSize="medium" color="secondary" sx={{ cursor: 'pointer' }} onClick={() => { setTaskToIncomplete(params.id) }} />
              </>
            )}
          </>
        )
      }
    ],
    rows: state.data.rows.map(x => (
      {
        ...x,
        id: x.id,
        shipmentId: x.shipmentId,
        description: x.description,
        creationDate: new Date(x.creationTime).toLocaleDateString(),
        creationTime: new Date(x.creationTime).toLocaleTimeString(),
        completedUserId: x.completedUserId,
      }))
  }


  const columnVisibilityOptions = ([
  ])

  const handleColumnVisibilityChange = (columns) => {
    setColumnVisibility((prevVisibility) => {
      const updatedVisibility = { ...prevVisibility };

      columns.forEach((column) => {
        updatedVisibility[column] = !prevVisibility[column];
      });

      return updatedVisibility;
    });
  };

  const handleEditClick = (id) => () => {
    const event = {
      target: {
        name: 'shipmentId',
        value: id
      }
    }
    handleInputChange(event)
    setDialogOpen(true)
  };

  const handleInputChange = (event) => {
    dispatch(serviceOpsFollowupActions.updateCommentValue(event.target.name, event.target.value));
  }

  const handleModalClose = () => {
    setDialogOpen(false)
  }

  const handleSubmitComment = () => {
    dispatch(serviceOpsFollowupActions.addComment(followupState.shipmentComment))
  }

  const setTaskCompleted = (selectedIds, action) => {
    const updatedRows = state.data.rows.filter(row => !selectedIds.includes(row.id));
    dispatch(serviceOpsTasksActions.setTasksCompleted(selectedIds, updatedRows))
  }

  const setTaskToIncomplete = (id) => {
    const updatedRows = state.data.rows.filter(row => row.id !== id);
    dispatch(serviceOpsTasksActions.setTaskToIncomplete(id, updatedRows))
  }

  const switchView = () => {
    dispatch(serviceOpsTasksActions.initate(!showCompleted));
    setShowCompleted(!showCompleted)
    setColumnVisibility({ completedUserId: !columnVisibility.completedUserId })
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      sx={{ height: '100%', width: '100%' }}>

      <Grid container item xs={12} alignItems="stretch" justifyContent="center">
        <Grid item xs={12} md={12}>
          <Card sx={{ overflow: "auto" }}>
            <SuiBox>
              <Grid container alignItems="strecth" justifyContent="center">
              </Grid>


              <SuiBox >
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={12}>
                    <Box sx={{ width: '100%' }}>
                      {useMemo(() => (
                        <DataGrid
                          rowHeight={38}
                          // rowHeight = {100}
                          rows={data.rows}
                          columns={data.columns}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 100,
                              },
                            },
                          }}
                          pageSizeOptions={[100]}
                          sx={{ fontSize: '12px' }}
                          columnVisibilityModel={columnVisibility}
                          // slots={{ toolbar: () => (<CustomToolbar columnVisibility={columnVisibility} columnVisibilityOptions={columnVisibilityOptions} columnVisibilityChange={columnVisibilityChange} checkedRowActions={checkedRowActions} selectionModel={selectionModel} moveSelected={moveSelected} />) }}
                          slots={{ toolbar: () => (<CustomToolbar checkedRowActions={checkedRowActions} columnVisibility={columnVisibility} columnVisibilityOptions={columnVisibilityOptions} columnVisibilityChange={handleColumnVisibilityChange} selectionModel={selectionModel} moveSelected={setTaskCompleted} tableSwitchButton={<SuiButton onClick={switchView}>{buttonText}</SuiButton>} />) }}
                          loading={state.loading}
                          slotProps={{
                            loadingOverlay: {
                              variant: 'skeleton',
                              noRowsVariant: 'skeleton',
                            },
                          }}
                        />
                      ), [data.rows, data.columns, state.loading, columnVisibility])}
                    </Box>
                    {/* <EditableDataTable rows={data.rows} columns={data.columns} saveRow={handleSaveRow} openModal={openModal} columnVisibility={columnVisibility} columnVisibilityOptions={columnVisibilityOptions} columnVisibilityChange={handleColumnVisibilityChange} /> */}
                  </Grid>
                </Grid>

              </SuiBox>
            </SuiBox>
          </Card>
        </Grid>
      </Grid>
      <ShipmentCommentsInput open={dialogOpen} selectedId={selectedId} handleClose={handleModalClose} handleInputChange={handleInputChange} handleSubmit={handleSubmitComment} state={followupState} />
    </Grid>
  )
}
