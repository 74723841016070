import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SuiButton from '_softUI/components/SuiButton'
import Checkbox from '@mui/material/Checkbox';
import { useState } from 'react';


export default function ShipmentCommentsInput({ open, selectedId, handleClose, handleInputChange, handleSubmit, state }) {
    const [checked, setChecked] = useState(false);
    const handleCheckboxChange = (event) => {
        const newEvent = {
            ...event,
            target: {
                ...event.target,
                name: event.target.name,
                value: event.target.checked,
            },
        };
        handleInputChange(newEvent);
      };

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                }}
            >
                <DialogTitle>Add comment</DialogTitle>
                <DialogContent>
                ID: {selectedId}
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="comment"
                        name="comment"
                        label="Comment"
                        fullWidth
                        variant="standard"
                        multiline
                        rows={5}
                        onChange={handleInputChange}
                    />
                    <DialogContentText>
                        Followup completed?
                        <Checkbox name="trackComplete"
                        checked={state.shipmentComment.trackComplete}
                        onChange={handleCheckboxChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <SuiButton onClick={handleClose}>Cancel</SuiButton>
                    <SuiButton onClick={handleSubmit}>Submit</SuiButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}