export const serviceOpsShipmentConstants = {
    LOADING: 'SERVICEOPS_LOADING',
    PRICINGDETAILS_LOADING: 'SERVICEOPS_PRICINGDETAILS_LOADING',
    UPDATE_BOOKINGS_TABLE: 'SERVICEOPS_UPDATE_BOOKINGS_TABLE',
    CLEAR: 'SERVICEOPS_CLEAR',
    UPDATE_SELECTED_SHIPMENT_IN_TABLE: 'SERVICEOPS_UPDATE_SELECTED_SHIPMENT_IN_TABLE',
    ERROR: 'SERVICEOPS_ERROR',
    MODAL_LOADING: 'SERVICEOPS_MODAL_LOADING',
    CLOSE_MODAL: 'SERVICEOPS_CLOSE_MODAL',
    UPDATE_SELECTED_SHIPMENT: 'SERVICEOPS_UPDATE_SELECTED_BOOKING',
    UPDATE_CARRIER_OPTIONS: 'SERVICEOPS_UPDATE_CARRIER_OPTIONS',
    UPDATE_SELECTED_PRICING_DETAILS: 'SERVICEOPS_UPDATE_SELECTED_PRICING_DETAILS',
    UPDATE_SELECTED_SHIPMENT_VALUE: 'SERVICEOPS_UPDATE_SELECTED_SHIPMENT_VALUE',
    UPDATE_ADR_ROW: 'SERVICEOPS_UPDATE_ADR_ROW',
    UPDATE_UNCODE_OPTIONS: 'SERVICEOPS_UPDATE_UNCODE_OPTIONS',
    UPDATE_ADRDESCRIPTION_OPTIONS: 'SERVICEOPS_UPDATE_ADRDESCRIPTION_OPTIONS',
    ADD_ADRLINE: 'SERVICEOPS_ADD_ADRLINE',
    REMOVE_ADRLINE: 'SERVICEOPS_REMOVE_ADRLINE',
    UPDATE_LOCATION_INPUT_VALUE: 'SERVICEOPS_UPDATE_LOCATION_INPUT_VALUE',
    ADD_GOODSLINE: 'SERVICEOPS_ADD_GOODSLINE',
    REMOVE_GOODSLINE: 'SERVICEOPS_REMOVE_GOODSLINE',
    ADD_ADDITIONAL_SERVICE: 'SERVICEOPS_ADD_ADDITIONAL_SERVICE',
    ADD_PRICING_DETAIL_TO_STATE: 'SERVICEOPS_ADD_PRICING_DETAIL_TO_STATE',
    DELETE_ADDITIONAL_SERVICE_ROW: 'SERVICEOPS_DELETE_ADDITIONAL_SERVICE_ROW',
    DELETE_PRICING_DETAIL_ROW: 'SERVICEOPS_DELETE_PRICING_DETAIL_ROW'
};