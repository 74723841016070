
import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import SelectWithLabel from "_components/FormFields/SelectWithLabel";
import InputWithLabel from "_components/FormFields/InputWithLabel";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { adrPkgTypes, adrPkgGroups, adrTypes } from '_helpers'
import { ShipmentInformationFormActions, serviceOpsShipmentActions } from '_actions';
import { useDispatch, useSelector } from 'react-redux';
import Arrow from '@mui/icons-material/SubdirectoryArrowRight';
import SuiTypography from "_softUI/components/SuiTypography";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import SuiButton from '_softUI/components/SuiButton';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

export function AdrRow({ adrIndex, goodsIndex, goods, shipmentFormType }) {

    const state = useSelector(state => state.ShipmentInformationForm)
   // const row = state.data.goods[goodsIndex]
   // const adrRow = state.data.goods[goodsIndex].adrRow[adrIndex]
    const row = goods[goodsIndex]
    const adrRow = goods[goodsIndex].adrRow[adrIndex]
    const last = (adrIndex + 1) === row.adrRow.length
    const actions = shipmentFormType === 'insta' ? ShipmentInformationFormActions : shipmentFormType === 'admin' ? serviceOpsShipmentActions : ShipmentInformationFormActions

    const defaultAdrClasses = adrTypes
    const technicalNameRequired = adrRow.technicalNameRequired
    const dispatch = useDispatch()

    const measurements = [
        { value: 1, label: 'KGM' },
        { value: 2, label: 'LTR' },
    ]

    useEffect(() => {
        handleAdrClassChange({ target: { name: 'class', value: adrRow.class } });
        if(adrRow.unCode){
            handleUnCodeChange({ target: { name: 'unCode', value: adrRow.unCode } })
        }
    }, []);

    function handleAdrClassChange(event) {
        dispatch(actions.getUnCodesForClass(event.target.value, adrIndex, goodsIndex))
        handleRowChange(event)
    }

    function handleUnCodeChange(event) {
        dispatch(actions.GetDescriptionsForUnCode(event.target.value, goodsIndex, adrIndex))
        handleRowChange(event)
    }

    function handleRowChange(event) {

          dispatch(actions.updateAdrRow(event.target.name, event.target.value, goodsIndex, adrIndex));
    }

    function handleRowWeightChange(event) {

        const value = event.target.value?.replace(',','.')

        dispatch(actions.updateAdrRow(event.target.name, value, goodsIndex, adrIndex));
  }

    function handleDescriptionChange(event) {
        dispatch(actions.updateAdrDescription(event.target.name, event.target.value, event.target.subsidiaryRisk, event.target.technicalNameRequired, event.target.tunnelCode, event.target.packingGroup, goodsIndex, adrIndex));
    }

    function checkboxChangeHandler(name, event) {
        dispatch(actions.updateAdrRow(name, event, goodsIndex, adrIndex));
    }

    function addAdrLine() {
        dispatch(actions.addAdrLine(goodsIndex));
    }

    function removeAdrLine() {
        dispatch(actions.removeAdrLine(goodsIndex, adrIndex));
    }

    console.log('shipmentTypeä', state.data.shipmentType)


    return (
        <>
            {adrIndex !== 0 &&
                <Divider sx={{ mt: 1, mb: 2 }} />
            }
            <Grid item sx={{
                borderRadius: '0px',

            }} container xs={12}
            // spacing={2} justifyContent="flex-start" alignItems="flex-start" 
            // mb={3} 
            // ml={-3}
            >


                <Grid item container xs={12} >
                    <Grid container item xs={12} spacing={0.99} sx={{ paddingRight: '10px' }}>
                        <Grid container item xs={12} >
                            <Grid item container xs={12} md={6}>
                                <Grid item xs={12}>
                                    <SuiTypography variant="h6" color="secondary" fontWeight="bold" textAlign="left" >
                                        {row.adrRow.length === 1 ? 'Dangerous goods' : `Dangerous goods line ${adrIndex + 1}`}
                                    </SuiTypography>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} md={6} sx={{ justifyContent: 'flex-end' }}>
                                <Grid item xs={8} display="flex" sx={{ justifyContent: 'flex-end', alignItems: 'center' }} >
                                    <FormControlLabel
                                        display="flex"
                                        sx={{ alignItems: 'center' }}
                                        control={
                                            <Checkbox
                                                checked={adrRow.marinePollutant}
                                                onChange={e => checkboxChangeHandler('marinePollutant', e.target.checked)}
                                                sx={{
                                                    marginRight: -2
                                                }}
                                            />
                                        }
                                    />
                                    <SuiTypography variant="button" fontWeight="regular" color="text" lineHeight="1.2rem" mt={0.5} marginRight={3}>
                                        Marine pollutant
                                    </SuiTypography>
                                    <FormControlLabel
                                        sx={{ alignItems: 'flex-start' }}
                                        control={
                                            <Checkbox
                                                checked={adrRow.limitedQuantity}
                                                onChange={e => checkboxChangeHandler('limitedQuantity', e.target.checked)}
                                                sx={{
                                                    marginRight: -2
                                                }}
                                            />
                                        }
                                    />
                                    <SuiTypography variant="button" fontWeight="regular" color="text" lineHeight="1.2rem" mt={0.5}>
                                        Limited quantity
                                    </SuiTypography>
                                </Grid>
                                {row.adrRow.length > 1 &&
                                    <Grid item xs={1} display="flex" alignItems="flex-start" justifyContent="flex-end" sx={{ paddingTop: '0px !important' }}>
                                        <SuiButton color="error" sx={{ minWidth: 'unset !important', paddingTop: '0px !important', paddingRight: '0px !important' }} variant="text" size="medium" onClick={removeAdrLine} >
                                            <ClearOutlinedIcon sx={{ height: '100%' }} />
                                        </SuiButton>
                                    </Grid>
                                }
                                {/* {adrIndex === 0 &&
                                    <Grid item xs={4} display="flex" justifyContent="flex-end"  >
                                        <SuiButton variant="outlined" size="xs" color="secondary" sx={{ height: '80%' }} onClick={addAdrLine} >+ Adr row</SuiButton>
                                    </Grid>
                                } */}
                            </Grid>

                        </Grid>

                        {/* class, uncode, description */}
                        <Grid item container xs={12} md={5} spacing={0.99}>
                        <Grid item xs={3}>
                            <SelectWithLabel
                                label="Class"
                                name="class"
                                error={adrRow.adrClassError}
                                options={state.data.shipmentType === 1 || state.data.shipmentType === 3 || !state.data.shipmentType ? defaultAdrClasses : state.data.adrClasses}
                                value={{ value: adrRow.class, label: adrRow.class }}
                                onChange={e => handleAdrClassChange({ target: { name: 'class', value: e.value } })}
                                mandatory={true}
                            />
                        </Grid>
                        <Grid item xs={3}>

                            <SelectWithLabel
                                error={adrRow.unCodeError}
                                label="UN Code"
                                name="unCode"
                                value={adrRow.unCodeOptions ? adrRow.unCodeOptions.find(obj => parseInt(obj.value) === adrRow.unCode) : null}
                                isDisabled={adrRow.class === ''}
                                options={adrRow.unCodeOptions}
                                onChange={e => handleUnCodeChange({ target: { name: 'unCode', value: e.value } })}
                                mandatory={true}
                            />

                        </Grid>

                        <Grid item xs={6}>
                            <SelectWithLabel
                                error={adrRow.adrDescriptionError}
                                label="Description"
                                isDisabled={adrRow.unCode === ''}
                                options={adrRow.adrDescriptionOptions}
                                name="description"
                                value={adrRow.adrDescriptionOptions ? adrRow.adrDescriptionOptions.find(obj => obj.value === adrRow.description) : null}
                                // value={row.adrRow.adrDescriptionOptions.find(obj => {
                                //     return obj.value === row.adrRow.description
                                //   })}
                                onChange={e => handleDescriptionChange({ target: { name: 'description', value: e.value, subsidiaryRisk: e.subsidiaryRisk, technicalNameRequired: e.technicalNameRequired, tunnelCode: e.tunnelCode, packingGroup: e.packingGroup } })}
                                mandatory={true}
                            />

                        </Grid>
                        </Grid>

                        {/* quantity, packagetype */}
                        <Grid item container xs={12} md={3} spacing={0.99}>
                        <Grid item xs={3}>
                            <InputWithLabel
                                name="quantity"
                                value={adrRow.quantity}
                                error={adrRow.adrQuantityError}
                                placeholder="#"
                                label={
                                    <>
                                        Qty
                                    </>
                                }
                                onChange={e => handleRowChange(e)}
                                mandatory={true}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <SelectWithLabel
                                name="packageType"
                                error={adrRow.adrPkgTypeError}
                                value={adrPkgTypes.find(obj => obj.value === adrRow.packageType)}
                                label={
                                    <>
                                        Package Type
                                        <Tooltip title="Package type" placement="top">
                                            <InfoOutlinedIcon color="primary" sx={{ marginLeft: '10px' }} />
                                        </Tooltip>
                                    </>
                                }
                                options={adrPkgTypes}
                                onChange={e => handleRowChange({ target: { name: 'packageType', value: e.value } })}
                                mandatory={true}
                            />
                        </Grid>
                        </Grid>

                        {/* weights etc */}
                        <Grid item container xs={12} md={3.99} spacing={0.99}>
                        <Grid item xs={3}>
                            <InputWithLabel
                                name="netWeight"
                                value={adrRow.netWeight}
                                error={adrRow.adrNetWeightError}
                                placeholder={adrRow.measurementUnit === 1 ? 'kg' : 'ltr'}
                                label={
                                    <>
                                        NW
                                        <Tooltip title="Net weight" placement="top">
                                            <InfoOutlinedIcon color="primary" sx={{ marginLeft: '10px' }} />
                                        </Tooltip>
                                    </>
                                }
                                onChange={e => handleRowWeightChange(e)}
                                mandatory={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <InputWithLabel
                                name="grossWeight"
                                value={adrRow.grossWeight}
                                error={adrRow.adrGrossWeightError}
                                placeholder={adrRow.measurementUnit === 1 ? 'kg' : 'ltr'}
                                label={
                                    <>
                                        GW
                                        <Tooltip title="Gross weight" placement="top">
                                            <InfoOutlinedIcon color="primary" sx={{ marginLeft: '10px' }} />
                                        </Tooltip>
                                    </>
                                }
                                onChange={e => handleRowWeightChange(e)}
                                mandatory={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <SelectWithLabel label="Unit" name="measurementUnit"
                                options={measurements}
                                error={adrRow.adrUnitError}
                                value={measurements.find(obj => obj.value === parseInt(adrRow.measurementUnit))}

                                onChange={e => handleRowChange({ target: { name: 'measurementUnit', value: e.value } })} mandatory={true} />
                        </Grid>
                        <Grid item xs={3}>
                            <InputWithLabel
                                value={adrRow.netVolume}
                                placeholder='m³'
                                label={
                                    <>
                                        NV
                                        <Tooltip title="Net volume" placement="top">
                                            <InfoOutlinedIcon color="primary" sx={{ marginLeft: '10px' }} />
                                        </Tooltip>
                                    </>
                                } name="netVolume" error={adrRow.adrNetVolumeError} onChange={e => handleRowChange(e)} mandatory={true} sx={{ textTransform: 'none !important' }} />
                        </Grid>
                        </Grid>
                        <Grid item container xs={12} mt={0.5} spacing={0.99}>
                            {technicalNameRequired &&
                                <Grid item container xs={5}>
                                    <Grid item xs={5}>
                                        <SuiTypography variant="button" fontWeight="regular" color="text" lineHeight="1.2rem" mt={0.5} marginRight={1}>
                                            Technical description:
                                        </SuiTypography>
                                        <SuiTypography component="label" variant="caption" fontWeight='light' textTransform="capitalize" sx={{ color: '#fd5c70' }} >
                                            *
                                        </SuiTypography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <InputWithLabel label="" name="technicalDescription" value={adrRow.technicalDescription} onChange={e => handleRowChange(e)}  error={adrRow.technicalNameError} />
                                    </Grid>
                                </Grid>
                            }
                            <Grid item xs={technicalNameRequired ? 4 : 6}>
                                <Stack direction="row" spacing={1}>
                                    {adrRow.packingGroup && <Chip label={`Packing group: ${adrRow.packingGroup}`} />}
                                    {adrRow.tunnelCode && <Chip label={`Tunnel restriction code: ${adrRow.tunnelCode}`} />}
                                </Stack>
                            </Grid>
                            {last &&
                                <Grid item xs={technicalNameRequired ? 3 : 6} display="flex" justifyContent="flex-end"  >
                                    <SuiButton variant="outlined" size="sm" color="secondary" onClick={addAdrLine} startIcon={<AddIcon />} sx={{ minHeight: '0px !important', height: '1.3rem', padding: '14px !important' }}> Adr row</SuiButton>
                                </Grid>
                            }
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
        </>
    )
}