import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import SuiBox from "_softUI/components/SuiBox";
import SuiTypography from "_softUI/components/SuiTypography";
import SuiButton from "_softUI/components/SuiButton";
import DropZone from "_components/Common/Dropzone"
import { arrayBufferToBase64 } from '_helpers/file'
import { shipmentActions, customerSpotRequestTableActions } from '_actions';
import { adminActions } from '_actions/admin.actions';
import SelectWithLabel from "_components/FormFields/SelectWithLabel";
import InputWithLabel from "_components/FormFields/InputWithLabel";
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { useConfirm } from 'material-ui-confirm';


const typeOptions = [{ value: 1, label: 'Labels' }, { value: 2, label: 'Waybill' }]

export default function Admin({ state, id, tableType }) {
    const dispatch = useDispatch()
    const [type, setType] = useState(1)
    const confirm = useConfirm();

    const windowLocation = window.location.origin;
    const handleFile = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {

                const entry = {
                    name: file.path,
                    data: arrayBufferToBase64(reader.result),
                    type: file.type,
                    bookingId: id,
                    fileType: type

                }

                dispatch(shipmentActions.saveFile(entry))
            }
            reader.readAsArrayBuffer(file)
        })

    }, [])

    const actions = state.shipmentType === 2 ? shipmentActions : customerSpotRequestTableActions

    function updateValue(name, value) {

        dispatch(actions.updateValue(name, value))
    }


    const saveTrackingNumber = (shipmentId) => {
        dispatch(shipmentActions.saveTrackingNumber(shipmentId, state.trackingNumber))

    }

    const setCanceled = (shipmentId) => {
        confirm({ description: 'Laiteaanko keikka varmasti tilaan canceled' })
        .then(() => {
            dispatch(adminActions.setCanceled(shipmentId))

        })
        .catch(() => { /* ... */ });


    }

    const setCompleted = (shipmentId) => {
        confirm({ description: 'Laiteaanko keikka varmasti tilaan completed' })
        .then(() => {
            console.log('test')
            dispatch(adminActions.setCompleted(shipmentId))
        })
        .catch(() => { /* ... */ });


    }

    const sendToApi = (shipmentId) => {

        dispatch(shipmentActions.sendToApi(shipmentId))



    }

    const getLabels = (shipmentId) => {
        dispatch(shipmentActions.getLabels(shipmentId))

    }

    const forwardDocuments = (shipmentId, email, lang, receiverType, clientUrl) => {

        if(state.trackingNumber === '' || state.trackingNumber === 'E99'){
            confirm({ description: 'Tracking numero on tyhjä. Lähetetäänkö viesti varmasti?' })
            .then(() => {
                dispatch(shipmentActions.forwardDocuments(shipmentId, email.trim(), lang, receiverType, clientUrl))
            })
            .catch(() => { /* ... */ });
    
        } else{
            dispatch(shipmentActions.forwardDocuments(shipmentId, email.trim(), lang, receiverType, clientUrl))
        }

      
   
    }

    const forwardSpotRequest = (shipmentId, email) => {

        dispatch(shipmentActions.forwardSpotRequest(shipmentId, email))

      
   
    }



    return (
        <>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                <SuiTypography variant="h6">Upload documents</SuiTypography>


            </SuiBox>

            <SuiBox p="1rem">
                <DropZone title="" hint="" action={handleFile} handleDelete={undefined} files={[]} acceptedFileFormats='application/pdf' />
                <SelectWithLabel
                    value={typeOptions.find(obj => {
                        return obj.value === type
                    })}
                    options={typeOptions}
                    label="Document type"
                    name="type"
                    onChange={e => setType(e.value)}
                />
            </SuiBox>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                <SuiTypography variant="h6">Tracking and API</SuiTypography>


            </SuiBox>
            {tableType !== 'carrierSpot' &&
            <SuiBox pt={2} px={2}>
                <Grid container >
                <Grid container xs={7} >
                    <Grid item xs={7}>
                        <InputWithLabel placeholder="tracking number" label="Tracking number" name="trackingNumber" value={state.trackingNumber ?? ''} onChange={(e) => updateValue(e.target.name, e.target.value)} />
                    </Grid>
                    <Grid item xs={5}>
                        <Grid item ml={2}>
                        <SuiButton sx={{ marginTop: '1.3rem' }} color="primary" onClick={() => saveTrackingNumber(id)}>Save tracking id</SuiButton>
                        </Grid>
                    </Grid>

                </Grid >
                <Grid container xs={5}  display='flex' justifyContent='flex-end'> 
                {/* <Grid item xs={12}  display='flex' justifyContent='flex-end' sx={{border: '1px solid black'}}> */}
                <Grid item xs={12} display='flex' justifyContent='flex-end'>
                    <Grid item >
                        <SuiButton sx={{ marginTop: '1.3rem' }} color="secondary" onClick={() => sendToApi(id)}>Send to API</SuiButton>
                    </Grid>
                </Grid>
            
            <Grid item xs={12} display='flex' justifyContent='flex-end'>
                <Grid item xs={12}>
                <SuiButton  sx={{ marginTop: '1.3rem' }} color="secondary" onClick={() => getLabels(id)}>Get labels</SuiButton>
                </Grid>
                <Grid item xs={12}>
                <SuiButton sx={{ marginTop: '1.3rem' }} color="danger" onClick={() => setCanceled(id)}>Set canceled</SuiButton>
                </Grid>
                <Grid item xs={12}>
                <SuiButton sx={{ marginTop: '1.3rem' }} color="danger" onClick={() => setCompleted(id)}>Set completed</SuiButton>
                </Grid>
            </Grid>
            {/* </Grid> */}
           
        </Grid>
        </Grid>




            </SuiBox> }
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                <Grid container xs={12} >
                    <Grid container item xs={12} >
                        <SuiTypography variant="h4">Document forwarding</SuiTypography>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item container mt={0.5} xs={12} spacing={2} >
                            <Grid container item xs={12}>
                                <Grid item xs={2}>
                                <SuiTypography variant="h6">Customer</SuiTypography>
                                </Grid>
                            <Grid item xs={4}>
                                <InputWithLabel placeholder="receiver email" label="Email" name="docFwdCustomerReceiver" onChange={(e) => updateValue(e.target.name, e.target.value)} value={state.docFwdCustomerReceiver} />
                            </Grid>
                            <Grid item xs={2}>
                            {state.documentsForwardedCustomer && <Chip sx={{ marginTop: '1.7rem' }}  label="Sent" color="success" />}
                            </Grid>
                            {/* <Grid item xs={5}> */}
                                {/* <SelectWithLabel
                                    label="Language"
                                    value={languages.find(obj => {
                                        return obj.value === state.docFwdCustomerLang
                                    })}
                                    options={languages}
                                    fullWidth
                                    size="medium"
                                    name="docFwdCustomerLang"


                                    onChange={e => updateValue('docFwdCustomerLang', e.value)} /> */}
                            {/* </Grid> */}
                         
                            <Grid item xs={4} display='flex' justifyContent='flex-end'>

                                <SuiButton sx={{ marginTop: '1.3rem' }} color="primary" onClick={() => forwardDocuments(id, state.docFwdCustomerReceiver, state.docFwdCustomerLang, 'customer', windowLocation)}>Send message</SuiButton>
                       
                                
                        
                            </Grid>
                            </Grid>
                        </Grid>
                        {state.shipmentDirection === 3 &&
                            <Grid container ml={2}>
                                <Grid item container mt={0.5} xs={12} spacing={2} >
                                <Grid container item xs={12}>
                                    <Grid item xs={2}>
                                    <SuiTypography variant="h6">Sender</SuiTypography>
                                    </Grid>
                               
                                <Grid item xs={4}>
                                    <InputWithLabel placeholder="sender email" label="Email" name="docFwdSenderReceiver" value={state.docFwdSenderReceiver} onChange={(e) => updateValue(e.target.name, e.target.value)} />
                                </Grid>
                                <Grid item xs={2}>
                            {state.documentsForwardedSender && <Chip sx={{ marginTop: '1.7rem' }}  label="Sent" color="success" />}
                            </Grid>
                                <Grid item xs={4} display='flex' justifyContent='flex-end'>

                                    <SuiButton sx={{ marginTop: '1.3rem' }} color="primary" onClick={() => forwardDocuments(id, state.docFwdSenderReceiver, 'ENG', 'sender', windowLocation)}>Send message</SuiButton>

                                </Grid>
                             
                            </Grid>
                            </Grid>
                            </Grid>}
                    </Grid>
                </Grid>
            </SuiBox>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                <Grid container xs={12} >
                    <Grid container item xs={12} >
                        <SuiTypography variant="h4">Spot Request forwarding</SuiTypography>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item container mt={0.5} xs={12} spacing={2} >
                            <Grid container item xs={12}>
                                <Grid item xs={2}>
                                <SuiTypography variant="h6">Customer</SuiTypography>
                                </Grid>
                            <Grid item xs={5}>
                                <InputWithLabel placeholder="receiver email" label="Email" name="spotRequestReceiver" onChange={(e) => updateValue(e.target.name, e.target.value)} value={state.spotRequestReceiver} />
                            </Grid>

                            <Grid item xs={5} display='flex' justifyContent='flex-end'>

                                <SuiButton sx={{ marginTop: '1.3rem' }} color="primary" onClick={() => forwardSpotRequest(id, state.spotRequestReceiver)}>Send message</SuiButton>

                            </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </SuiBox>


        </>
    )
}