export const incoterms = [
  { value: 1, label: 'EXW' },
  { value: 2, label: 'FCA' },
  { value: 3, label: 'FOB' },
  { value: 4, label: 'FAS' },
  { value: 5, label: 'CFR' },
  { value: 6, label: 'CIF' },
  { value: 7, label: 'CIP' },
  { value: 8, label: 'CPT' },
  { value: 9, label: 'DAT' },
  { value: 10, label: 'DAP' },
  { value: 11, label: 'DDP' },

]


export const incotermsInstantExport = [
  { value: 10, label: 'DAP' },
  { value: 11, label: 'DDP' },
]

export const incotermsInstantImport = [


  { value: 1, label: 'EXW' },
  { value: 2, label: 'FCA' },

]


export const transportationTypes = [
  { value: 1, label: 'Road' },
  { value: 2, label: 'Air' },
  { value: 3, label: 'Sea' },
  { value: 4, label: 'Railway' },

]

export const adrTypes = [
  { value: '2.1', label: '2.1' },
  { value: '2.2', label: '2.2' },
  { value: '2.3', label: '2.3' },
  { value: '3', label: '3' },
  { value: '4.2', label: '4.2' },
  { value: '4.3', label: '4.3' },
  { value: '5.1', label: '5.1' },
  { value: '5.2', label: '5.2' },
  { value: '6.1', label: '6.1' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },

]

export const languages = [
  { value: 'FI', label: 'FI' },
  { value: 'ENG', label: 'ENG' },


]

export const countries = [
  { value: 73, label: 'FI' },
  { value: 200, label: 'SE' },
]

export const typeOptionsRoad = [
  { value: 1, label: 'Colli' },
  { value: 2, label: 'EUR-pll (120x80)' },
  { value: 3, label: 'FIN-pll (120x100)' },
  { value: 4, label: 'Half-pll (60x80)' },
  { value: 5, label: 'Full Trailer' },
  { value: 7, label: 'Parcel (max 70kg)' },
]

export const typeOptionsInstant = [
  { value: 1, label: 'Colli' },
  { value: 7, label: 'Parcel (max 70kg)' },
  { value: 2, label: 'EUR-pll (120x80)' },
  { value: 3, label: 'FIN-pll (120x100)' },
  { value: 4, label: 'Half-pll (60x80)' },


]

export const typeOptionsAir = [
  { value: 1, label: 'Colli' },

]

export const typeOptionsSea = [
  { value: 6, label: 'Container' },
  { value: 1, label: 'Colli' },
  { value: 2, label: 'EUR-pll (120x80)' },
  { value: 3, label: 'FIN-pll (120x100)' },
  { value: 4, label: 'Half-pll (60x80)' },

]

export const fullTrailerTypes = [
  { value: 1, label: 'Semi-trailer (13.6ldm)' },
  // { value: 2, label: 'Full-trailer' },
  // { value: 3, label: 'Bulk-trailer' },
]

export const containerTypes = [
  { value: 1, label: '20DC' },
  { value: 2, label: '20PW' },
  { value: 3, label: '40DC' },
  { value: 4, label: '40HC' },
  { value: 5, label: '45HC' },
  { value: 6, label: '45HCPW' },
  { value: 7, label: '20OT' },
  { value: 8, label: '40OT' },
  { value: 9, label: '20RF' },
  { value: 10, label: '40RF' },
]

export const stackableOptions = [
  { value: false, label: 'No' },
  { value: true, label: 'Yes' },
]

export const adrOptions = [
  { value: false, label: 'No' },
  { value: true, label: 'Yes' },
]

export const carrierLabelDeliveryType = [
  { value: 0, label: 'Freightopt sends' },
  { value: 1, label: 'With driver' },
  { value: 2, label: 'No labels' },
  { value: 3, label: 'Export/Domestic Freightopt, Import driver' },
]

export const adrPkgTypes = [
  { value: 1, label: "BAG" },
  { value: 2, label: "BOX" },
  { value: 3, label: "DRUM" },
  { value: 4, label: "IBC - Intermediate bulk container" },
  { value: 5, label: "JERRY - Jerry can" },
  { value: 6, label: "CP" },
  { value: 7, label: "LGM" },
  { value: 8, label: "BOB" },
  { value: 9, label: "PD" },
  { value: 10, label: "SB" },
  { value: 11, label: "DRM" },
  { value: 12, label: "JCN" },
]

export const additionalServiceOptions = [

  { value: "tailLift", label: "Tail lift loading / dismantling" },
  { value: "insurance", label: "Insurance" },
  { value: "adr", label: "ADR" },
  { value: "importDeclaration", label: "Import process" },
  { value: "callOnPickup", label: "Call before pickup" },
  { value: "callOnDelivery", label: "Call before delivery" },
  { value: "exportDeclaration", label: "Export process" },
  { value: "temperatureControl", label: "Temperature control" },
  { value: "consumerDelivery", label: "Consumer delivery" },
  { value: "heatedTransport", label: "Heated transport" }

]