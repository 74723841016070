import React, {  useEffect} from 'react';

import { GoodsRow } from '../Common/GoodsRow'
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { useDispatch, useSelector } from 'react-redux';

import { instantPricingActions } from '../../_actions';



export function InputDetailsContainer() {

  const goods = useSelector(state => state.instantPricing.goods)
  const state = useSelector(state => state.instantPricing)
  const dispatch = useDispatch()

  function handleRowChange(event, index) {
    console.log(event )
    dispatch(instantPricingActions.updateDetailRow(event.target.name, event.target.value, index));

  }

  function addGoodsLine() {
    dispatch(instantPricingActions.addDetailRow());
  }

  function removeGoodsLine(index) {
    dispatch(instantPricingActions.removeDetailRow(index));
  }

  function handleStackableChange(event, index) {

    dispatch(instantPricingActions.updateDetailRow('stackable', event.target.checked, index));
  }

  function calculateBillingWeight(row,index){
 
  }

  function handleAdrClassChange(name, value, rowIndex){
    dispatch(instantPricingActions.updateAdrValue(name, value, rowIndex))
  }

  useEffect(() => {
    dispatch(instantPricingActions.getBillingWeight(state.goods))
  }, [state.goods])

  return (
          <>
          {/* <h3>Input details</h3> */}
          <Grid sx={{mt:'0rem', mb:'1rem'}} container alignContent="center" justifyContent="center" >
            <Grid item xs={12} md={12} lg={12}  >
              {goods.map((row, index) =>
                <GoodsRow
                  key={index}
                  index={index}
                  handleRowChange={handleRowChange}
                  addGoodsLine={addGoodsLine}
                  removeGoodsLine={removeGoodsLine}
                  handleStackableChange={handleStackableChange}
                  calculateBillingWeight={calculateBillingWeight}
                  handleAdrClassChange={handleAdrClassChange}
                  goods={goods}
                  showDescription={false}
                  state={state}
                  shipmentFormType={'insta'}
                />
                // <InputDetailsRow key={index} index={index} last={index === detailRows.length - 1} only={detailRows.length === 1} />
              )}

            </Grid>
            {state.showError && 
            <Grid item xs={12}>
            <Chip label={state.errorMessage} sx={{width:'100%', borderRadius:'5px'}} size="small" color="error" variant="outlined" />
            </Grid>
            }
               {state.showWarning && 
            <Grid item xs={12}>
            <Chip label={state.errorMessage} sx={{width:'100%', borderRadius:'5px'}} size="small" color="warning" variant="outlined" />
            </Grid>
            }
          </Grid>
          </>
  )
}