import { serviceOpsTasksConstants } from '../../_constants';


const initialState = {
    loading: false,
    viewState: 'incomplete',
    data: {
        rows: []
    },
    changeTrigger: false
};

export function serviceOpsTasks(state = initialState, action) {
    switch (action.type) {
        case serviceOpsTasksConstants.LOADING:
            return {
                ...state,
                loading: true
            }

        case serviceOpsTasksConstants.ERROR:
            return {
                ...state,
                loading: false
            };

        case serviceOpsTasksConstants.UPDATE_TASKS_TABLE:
            return {
                ...state,
                loading: false,
                tableUpdateLoading: false,
                viewState: action.viewState,
                data: {
                    ...state.data,
                    rows: action.data
                },
            };
        
        case serviceOpsTasksConstants.UPDATE_CHANGE_TRIGGER:
            return {
                ...state,
                changeTrigger: action.changeTrigger
            }

        default:
            return state
    }
}