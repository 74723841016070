import { serviceOpsInvoicingConstants } from '../../_constants';
import { serviceOpsService } from '../../_services';

export const serviceOpsInvoicingActions = {
    getInvoicingInformation,
    updateCarrierForInvoicing,
    setDataFromExcel,
    addPricesToShipments
}   

function setDataFromExcel(data) {
    return { type: serviceOpsInvoicingConstants.SET_DATA_FROM_EXCEL, data }
}

function getInvoicingInformation(idArray, carrier) {
    return dispatch => {
        // dispatch(request(true));

        serviceOpsService.getInvoicingInformation(idArray, carrier)
            .then(
                data => {
                    console.log('data', data)
                    // clear();
                    dispatch(success(data));

                },
                error => {
                    dispatch(failure(error));

                }
            )

        // function request(value) { return { type: serviceOpsInvoicingConstants.MODAL_LOADING, value} }
        // function clear() { return { type: serviceOpsInvoicingConstants.CLEAR } }
        function success(data) { return { type: serviceOpsInvoicingConstants.UPDATE_INVOICING_TABLE, data } }
        function failure(error) { return { type: serviceOpsInvoicingConstants.ERROR, error } }

    }
}

function updateCarrierForInvoicing(name, value) {
    const data = { name, value }
    return { type: serviceOpsInvoicingConstants.UPDATE_INVOICING_CARRIER_VALUE, data }
}

function addPricesToShipments(data) {
    return dispatch => {
        // dispatch(request(true));

        serviceOpsService.addPricesToShipments(data)
            .then(
                data => {
                    console.log('data', data)
                    // clear();
                    dispatch(success(data));

                },
                error => {
                    dispatch(failure(error));

                }
            )

        // function request(value) { return { type: serviceOpsInvoicingConstants.MODAL_LOADING, value} }
        // function clear() { return { type: serviceOpsInvoicingConstants.CLEAR } }
        function success(data) { return { type: serviceOpsInvoicingConstants.UPDATE_INVOICING_TABLE, data } }
        function failure(error) { return { type: serviceOpsInvoicingConstants.ERROR, error } }

    }
}
