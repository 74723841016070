import { serviceOpsFollowupConstants, serviceOpsShipmentConstants } from '../../_constants';
import { serviceOpsService } from '../../_services';

export const serviceOpsFollowupActions = {

    initate,
    closeModal,
    updateCommentValue,
    addComment,
    getCommentsAndStatusesById
};

function initate() {
    return dispatch => {
        dispatch(request());

        serviceOpsService.getFollowupShipments()
            .then(
                data => {
                    dispatch(success(data));

                },
                error => {
                    dispatch(failure(error));
                }
            )

        function request() { return { type: serviceOpsFollowupConstants.LOADING } }
        function success(data) { return { type: serviceOpsFollowupConstants.UPDATE_FOLLOWUP_TABLE, data } }
        function failure(error) { return { type: serviceOpsFollowupConstants.ERROR, error } }

    }
}

function closeModal() {


    return dispatch => {
        dispatch(close());
        function close() { return { type: serviceOpsShipmentConstants.CLOSE_MODAL } }

    }



}

function updateCommentValue(name, value) {

    return dispatch => {
      const data = { name, value }
      dispatch(update(data));
    }
  
    function update(data) { return { type: serviceOpsFollowupConstants.UPDATE_COMMENT_VALUE, data }}
   
  
  }

  function addComment(comment) {

    return dispatch => {
        serviceOpsService.addShipmentComment(comment)
            .then(
                data => {
                    dispatch(update(data));
                },
                error => {
                    dispatch(failure(error));
                }
            )

        function update(data) { return { type: serviceOpsFollowupConstants.UPDATE_SHIPMENT_COMMENTS, data } }
        function failure(error) { return { type: serviceOpsFollowupConstants.ERROR, error } }

    }
}

function getCommentsAndStatusesById(id){
    return dispatch => {
        dispatch(request());
        
        serviceOpsService.getCommentsAndStatusesById(id)
            .then(
                data => {
                    dispatch(success(data));

                },
                error => {
                    dispatch(failure(error));
                }
            )

        function request() { return { type: serviceOpsFollowupConstants.COMMENTS_LOADING } }
        function success(data) { return { type: serviceOpsFollowupConstants.UPDATE_SELECTED_SHIPMENT_COMMENTS_AND_STATUSES, data } }
        function failure(error) { return { type: serviceOpsShipmentConstants.ERROR, error } }
    }
}






