import { dataURItoBlob } from 'dropzone';
import { serviceOpsShipmentConstants, instantPricingConstants, alertConstants, serviceOpsConstants } from '../../_constants';
import { adminService, shipmentService, serviceOpsService, locationService } from '../../_services';

export const serviceOpsShipmentActions = {

    initate,
    closeModal,
    getShipmentById,
    updateShipmentInformation,
    getCarrierOptions,
    getPricingDetails,
    addPricingDetail,
    addAdditionalService,
    addPricingDetailToState,
    deleteAdditionalServiceRow,
    deletePricingDetailRow,
    moveSelectedToFollowUp,
    updateBasicValue,
    updateGoodsLine,
    getAllCountries,
    updateLocationValue,
    updateAdrRow,
    getUnCodesForClass,
    GetDescriptionsForUnCode,
    updateAdrDescription,
    addAdrLine,
    removeAdrLine,
    updateShipment,
    saveBillingDetails,
    updateLocationInputValue,
    addGoodsLine,
    removeGoodsLine,
};

function initate(type) {
    return dispatch => {
        dispatch(request());

        serviceOpsService.getAdminShipments(type)
            .then(
                data => {
                    dispatch(getCarrierOptions());
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error));

                }
            )

        function request() { return { type: serviceOpsShipmentConstants.LOADING } }
        function success(data) { return { type: serviceOpsShipmentConstants.UPDATE_BOOKINGS_TABLE, data } }
        function failure(error) { return { type: serviceOpsShipmentConstants.ERROR, error } }

    }
}

function getAllCountries() {
    return dispatch => {
        dispatch(request());

        locationService.getAllCountries()
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error));
                }
            )

        function request() { return { type: serviceOpsShipmentConstants.LOADING } }
        function success(data) { return { type: instantPricingConstants.UPDATE_COUNTRIES, data } }
        function failure(error) { return { type: serviceOpsShipmentConstants.ERROR, error } }
    }
}

function getCarrierOptions() {
    return dispatch => {

        serviceOpsService.getCarrierOptions()
            .then(
                data => {
                    dispatch(success(data));

                },
                error => {
                    dispatch(failure(error));
                }
            )

        function success(data) { return { type: serviceOpsShipmentConstants.UPDATE_CARRIER_OPTIONS, data } }
        function failure(error) { return { type: serviceOpsShipmentConstants.ERROR, error } }
    }
}

function getPricingDetails(id) {
    return dispatch => {
        dispatch(request());

        serviceOpsService.getPricingDetails(id)
            .then(
                data => {
                    dispatch(success(data));

                },
                error => {
                    dispatch(failure(error));
                }
            )

        function request() { return { type: serviceOpsShipmentConstants.PRICINGDETAILS_LOADING } }
        function success(data) { return { type: serviceOpsShipmentConstants.UPDATE_SELECTED_PRICING_DETAILS, data } }
        function failure(error) { return { type: serviceOpsShipmentConstants.ERROR, error } }
    }
}

function getShipmentById(id, purchasePrice) {
    return dispatch => {
        dispatch(request(true));

        shipmentService.getShipmentById(id)
            .then(
                data => {
                    clear();
                    dispatch(success(data, purchasePrice));
                },
                error => {
                    dispatch(failure(error));

                }
            )

        function clear() { return { type: serviceOpsShipmentConstants.CLEAR } }
        function request(value) { return { type: serviceOpsShipmentConstants.MODAL_LOADING, value } }
        function success(data, purchasePrice) { return { type: serviceOpsShipmentConstants.UPDATE_SELECTED_SHIPMENT, data, purchasePrice } }
        function failure(error) { return { type: serviceOpsShipmentConstants.ERROR, error } }

    }
}

function updateShipmentInformation(row) {
    return dispatch => {

        serviceOpsService.updateShipmentInformation(row)
            .then(
                data => {
                    dispatch(success(row));

                },
                error => {
                    dispatch(failure(error));

                }
            )

        function success(row) { return { type: serviceOpsShipmentConstants.UPDATE_SELECTED_SHIPMENT_IN_TABLE, row } }
        function failure(error) { return { type: serviceOpsShipmentConstants.ERROR, error } }

    }
}



function closeModal() {

    return dispatch => {
        dispatch(close());

        function close() { return { type: serviceOpsShipmentConstants.CLOSE_MODAL } }
    }
}


function moveSelectedToFollowUp(ids) {
    return dispatch => {

        serviceOpsService.moveShipmentToFollowup(ids)
            .then(
                data => {
                    dispatch(success('Shipment(s) moved!'));
                    dispatch(navigate(2))
                },
                error => {
                    dispatch(failure(error));
                }
            )

        function success(message) { return { type: alertConstants.SUCCESS, message } }
        function navigate(newValue) { return { type: serviceOpsConstants.SET_TAB_VALUE, newValue } }
        function failure(error) { return { type: alertConstants.ERROR, error } }

    }
}


function addPricingDetail(bookingId, name, value) {
    const data = { name, value }
    return dispatch => {
        serviceOpsService.addPricingDetail(bookingId, data)
            .then(
                data => {
                    dispatch(success(data.payload))
                },
                error => {
                    dispatch(failure(error))
                }
            )
        function success(data) { return { type: serviceOpsShipmentConstants.UPDATE_SELECTED_PRICING_DETAILS, data } }
        function failure(error) { return { type: serviceOpsShipmentConstants.ERROR, error } }
    }
}

function addAdditionalService(value, name, price) {
    const data = { name, value, price }
    return { type: serviceOpsShipmentConstants.ADD_ADDITIONAL_SERVICE, data };
}

function addPricingDetailToState(name, price) {
    const data = { name, price }
    return { type: serviceOpsShipmentConstants.ADD_PRICING_DETAIL_TO_STATE, data };
}

function deleteAdditionalServiceRow(id, type) {
    const data = { id, type }
    return { type: serviceOpsShipmentConstants.DELETE_ADDITIONAL_SERVICE_ROW, data };
}

function deletePricingDetailRow(id, type) {
    const data = { id, type }
    return { type: serviceOpsShipmentConstants.DELETE_PRICING_DETAIL_ROW, data };
}

function updateBasicValue(name, value) {
    const data = { name, value, type: 'basic' }
    return { type: serviceOpsShipmentConstants.UPDATE_SELECTED_SHIPMENT_VALUE, data };
}


function updateGoodsLine(name, value, index) {
    return dispatch => {
        const type = name === 'typeCode' ? 'typeCode' : 'goodsLine'
        const data = { name, value, type: type, index }
        dispatch(update(data));
    }

    function update(data) { return { type: serviceOpsShipmentConstants.UPDATE_SELECTED_SHIPMENT_VALUE, data } }

}

function updateLocationValue(name, value, index) {
    const data = { name, value, type: 'location', index }
    return { type: serviceOpsShipmentConstants.UPDATE_SELECTED_SHIPMENT_VALUE, data };
}

function updateAdrRow(name, value, index, adrIndex) {

    return dispatch => {
        const data = { name, value, index, adrIndex }
        dispatch(update(data));

    }

    function update(data) { return { type: serviceOpsShipmentConstants.UPDATE_ADR_ROW, data } }


}

function getUnCodesForClass(adrClass, adrIndex, goodsIndex) {
    return dispatch => {

        shipmentService.getUnCodesForClass(adrClass)
            .then(
                data => {
                    const formattedData = data.map(item => ({
                        value: item.unCode,
                        label: item.unCode,
                    }));
                    dispatch(success(formattedData));

                },
                error => {
                    dispatch(failure(error));
                }
            )

        function success(data) { return { type: serviceOpsShipmentConstants.UPDATE_UNCODE_OPTIONS, data, adrIndex, goodsIndex } }
        function failure(error) { return { type: serviceOpsShipmentConstants.ERROR, error } }
    }
}

function GetDescriptionsForUnCode(unCode, goodsIndex, adrIndex) {
    return dispatch => {

        shipmentService.GetDescriptionsForUnCode(unCode)
            .then(
                data => {
                    const formattedData = data.map(item => ({
                        value: item.description,
                        label: item.description + ' / ' + item.packingGroup + ' / (' + item.tunnelCode + ')',
                        subsidiaryRisk: item.subsidiaryRisk,
                        technicalNameRequired: item.technicalNameRequired,
                        tunnelCode: item.tunnelCode,
                        packingGroup: item.packingGroup
                    }));
                    dispatch(success(formattedData));

                },
                error => {
                    dispatch(failure(error));
                }
            )

        function success(data) { return { type: serviceOpsShipmentConstants.UPDATE_ADRDESCRIPTION_OPTIONS, data, goodsIndex, adrIndex } }
        function failure(error) { return { type: serviceOpsShipmentConstants.ERROR, error } }

    }
}

function updateAdrDescription(name, value, subsidiaryRisk, technicalNameRequired, tunnelCode, packingGroup, index, adrIndex) {

    return dispatch => {
        const data = { name, value, subsidiaryRisk, technicalNameRequired, tunnelCode, packingGroup, index, adrIndex }
        dispatch(update(data));

    }

    function update(data) { return { type: serviceOpsShipmentConstants.UPDATE_ADR_ROW, data } }


}

function addAdrLine(goodsIndex) {
    return { type: serviceOpsShipmentConstants.ADD_ADRLINE, goodsIndex };
}

function removeAdrLine(goodsIndex, adrIndex) {

    const data = { goodsIndex, adrIndex }
    return { type: serviceOpsShipmentConstants.REMOVE_ADRLINE, data };
}

function updateShipment(data) {

    return dispatch => {
        dispatch(loading());
        const model = {
            row: data,
            type: 'form'
        }
        serviceOpsService.updateShipment(data)
            .then(
                data => {
                    if (data.success) {
                        dispatch(update(model));
                        dispatch(success('saved!'))
                    }
                    else {
                        dispatch(failure(data.payload));
                    }
                },
                error => {
                    dispatch(failure(error));

                }
            )

        function success(message) { return { type: alertConstants.SUCCESS, message } }
        function loading() { return { type: serviceOpsShipmentConstants.MODAL_LOADING } }
        function update(data) { return { type: serviceOpsShipmentConstants.UPDATE_SELECTED_SHIPMENT_IN_TABLE, data } }
        function failure(error) { return { type: alertConstants.ERROR, error } }

    }


}


function saveBillingDetails(shipment, billingInformation ) {
    return dispatch => {

        const model = {
            shipment: shipment,
            billingInformation: billingInformation,
            type: 'pricing'
        }
        serviceOpsService.saveBillingDetails(model)
            .then(
                data => {
                    dispatch(update(model));
                    dispatch(success('saved!'))
                },
                error => {
                    dispatch(failure(error));
                }
            )

        function success(message) { return { type: alertConstants.SUCCESS, message } }
        function update(data) { return { type: serviceOpsShipmentConstants.UPDATE_SELECTED_SHIPMENT_IN_TABLE, data } }
        function failure(error) { return { type: serviceOpsShipmentConstants.ERROR, error } }

    }
}

function updateLocationInputValue(name, value, index) {
    const data = { name, value, index }
    return { type: serviceOpsShipmentConstants.UPDATE_LOCATION_INPUT_VALUE, data };
}

function addGoodsLine() {
    return { type: serviceOpsShipmentConstants.ADD_GOODSLINE };
  }

  function removeGoodsLine(index) {
    const data = { index }
    return { type: serviceOpsShipmentConstants.REMOVE_GOODSLINE, data };
  }








