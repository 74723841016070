import { shipmentConstants, spotRequestConstants, miscConstants } from '../_constants';
import { shipmentService, spotRequestService } from '../_services';
import { adminService } from '../_services';
import { alertConstants } from "_constants/alert.constants";
import { spotRequest } from '_reducers/spotRequest.reducer';

export const shipmentActions = {
    initate,
    getShipmentById,
    closeModal,
    saveFile,
    deleteFile,
    saveTrackingNumber,
    updateValue,
    forwardDocuments,
    sendToApi,
    getLabels,
    forwardSpotRequest

};



  function closeModal() {


    return dispatch => {
        dispatch(initate(2, 'update'));
        dispatch(close());


        function close() { return { type:  shipmentConstants.CLOSE_MODAL } }

    }



}

function initate(type, loader) {
    return dispatch => {
        dispatch(request());

        shipmentService.getAllShipmentsForUser(type)
            .then(
                data => {
                    dispatch(success(data));

                },
                error => {
                    dispatch(failure(error));
                 
                }
            )

        function request() { return { type: shipmentConstants.LOADING, loader } }
        function success(data) { return { type: shipmentConstants.UPDATE_BOOKINGS_TABLE, data } }
        function failure(error) { return { type: shipmentConstants.ERROR, error } }

    }
}

function getShipmentById(id) {
    return dispatch => {
        dispatch(request(true));

        shipmentService.getShipmentById(id)
            .then(
                data => {
                    clear();
                    dispatch(success(data));

                },
                error => {
                    dispatch(failure(error));
                 
                }
            )

        function request(value) { return { type: shipmentConstants.MODAL_LOADING, value} }
        function clear() { return { type: shipmentConstants.CLEAR } }
        function success(data) { return { type: shipmentConstants.UPDATE_SELECTED_SHIPMENT, data } }
        function failure(error) { return { type: shipmentConstants.ERROR, error } }

    }
}

function saveFile(file) {
    return dispatch => {
        dispatch(loading(true));

        shipmentService.saveFile(file)
            .then(
                data => {
                    if(data.success){
                        dispatch(getShipmentById(file.bookingId))
                        dispatch(success('Saved!'));
                        dispatch(loading(false));
                    }
                    else{
                        dispatch(failure(data.message))
                        dispatch(loading(false));
                    }
                  

                },
                error => {
                    dispatch(failure(error));
                    dispatch(loading(false));
                 
                }
            )

        function failure(message) { return { type: alertConstants.ERROR, message } }
        function success(message) { return { type: alertConstants.SUCCESS, message } }
        function loading(value = true) { return { type: shipmentConstants.MODAL_LOADING, value } }

    }
}

function forwardDocuments(shipmentId, email, lang, receiverType, clientUrl) {
    console.log(clientUrl)
    return dispatch => {
        dispatch(loading(true));
        const model ={
            shipmentId,
            email,
            language:lang,
            receiverType,
            clientUrl
        }
        adminService.forwardDocuments(model)
            .then(
                data => {
                    if(data.success){
           
                        dispatch(success('Sent!'));
                        dispatch(getShipmentById(shipmentId))
                        dispatch(loading(false));
                    }
                    else{
                        dispatch(failure(data.message))
                        dispatch(loading(false));
                    }
                  

                },
                error => {
                    dispatch(failure(error));
                    dispatch(loading(false));
                 
                }
            )

        function failure(message) { return { type: alertConstants.ERROR, message } }
        function success(message) { return { type: alertConstants.SUCCESS, message } }
        function loading(value = true) { return { type: shipmentConstants.MODAL_LOADING, value } }

    }
}

function forwardSpotRequest(shipmentId, emails) {
    return dispatch => {
        dispatch(loading(true));
        const model ={
            shipmentId,
            emails,
        }
        adminService.forwardSpotRequest(model)
            .then(
                data => {
                    if(data.success){
           
                        dispatch(success(data.message));
                        dispatch(loading(false));
                    }
                    else{
                        dispatch(failure(data.message))
                        dispatch(loading(false));
                    }
                  

                },
                error => {
                    dispatch(failure(error));
                    dispatch(loading(false));
                 
                }
            )

        function failure(message) { return { type: alertConstants.ERROR, message } }
        function success(message) { return { type: alertConstants.SUCCESS, message } }
        function loading(value = true) { return { type: shipmentConstants.MODAL_LOADING, value } }

    }
}

function deleteFile(id,bookingId) {
    return dispatch => {
        dispatch(loading(true));
        shipmentService.deleteFile(id)
            .then(
                data => {
                    if(data.success){
                        dispatch(getShipmentById(bookingId))
                        dispatch(success('Deleted!'));
                        dispatch(loading(false));
                    }
                    else{
                        dispatch(failure(data.message))
                        dispatch(loading(false));
                    }
                  

                },
                error => {
                    dispatch(failure(error));
                    dispatch(loading(false));
                 
                }
            )

        function failure(message) { return { type: alertConstants.ERROR, message } }
        function success(message) { return { type: alertConstants.SUCCESS, message } }
        function loading(value = true) { return { type: shipmentConstants.MODAL_LOADING, value } }

    }
}


function saveTrackingNumber(bookingId,trackingNumber) {
    return dispatch => {
        dispatch(loading(true));

        adminService.saveTrackingNumber(bookingId,trackingNumber)
            .then(
                data => {


                    if(data.success){
                        dispatch(getShipmentById(bookingId))
                        dispatch(success('Saved!'));
                        dispatch(loading(false));
                    }
                    else{
                        dispatch(failure(data.message))
                        dispatch(loading(false));
                    }
                  

                },
                error => {
                    dispatch(failure(error));
                    dispatch(loading(false));
                 
                }
            )

        function failure(message) { return { type: alertConstants.ERROR, message } }
        function success(message) { return { type: alertConstants.SUCCESS, message } }
        function loading(value = true) { return { type: shipmentConstants.MODAL_LOADING, value } }

    }
}

function sendToApi(bookingId) {
    return dispatch => {
        dispatch(loading(true));

        adminService.sendToApi(bookingId)
            .then(
                data => {


                    if(data.success){
                        dispatch(getShipmentById(bookingId))
                        dispatch(success('Success!'));
                        dispatch(loading(false));
                    }
                    else{
                        dispatch(failure(data.message))
                        dispatch(loading(false));
                    }
                  

                },
                error => {
                    dispatch(failure(error));
                    dispatch(loading(false));
                 
                }
            )

        function failure(message) { return { type: alertConstants.ERROR, message } }
        function success(message) { return { type: alertConstants.SUCCESS, message } }
        function loading(value = true) { return { type: shipmentConstants.MODAL_LOADING, value } }

    }
}

function getLabels(bookingId) {
    return dispatch => {
        dispatch(loading(true));

        adminService.getLabels(bookingId)
            .then(
                data => {


                    if(data.success){
                        dispatch(getShipmentById(bookingId))
                        dispatch(success('Success!'));
                        dispatch(loading(false));
                    }
                    else{
                        dispatch(failure(data.message))
                        dispatch(loading(false));
                    }
                  

                },
                error => {
                    dispatch(failure(error));
                    dispatch(loading(false));
                 
                }
            )

        function failure(message) { return { type: alertConstants.ERROR, message } }
        function success(message) { return { type: alertConstants.SUCCESS, message } }
        function loading(value = true) { return { type: shipmentConstants.MODAL_LOADING, value } }

    }
}

function updateValue(name, value) {


    return dispatch => {

      const data = { name, value }
      dispatch(update(data));
  
    }
  
    function update(data) { return { type: shipmentConstants.UPDATE_VALUE, data }}
   
  
  }



