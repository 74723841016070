import { serviceOpsService } from '../../_services';
import { serviceOpsTasksConstants } from '../../_constants';

export const serviceOpsTasksActions = {
    initate,
    checkForChanges,
    updateChangeTrigger,
    setTasksCompleted,
    setTaskToIncomplete,
}

function initate(completed){ 
    const viewState = completed ? 'complete' : 'incomplete'
    return dispatch => {
        dispatch(request());

        serviceOpsService.getAdminTasks(completed)
            .then(
                data => {
                    dispatch(success(data, viewState));

                },
                error => {
                    dispatch(failure(error));

                }
            )

        function request() { return { type: serviceOpsTasksConstants.LOADING } }
        function success(data, viewState) { return { type: serviceOpsTasksConstants.UPDATE_TASKS_TABLE, data, viewState } }
        function failure(error) { return { type: serviceOpsTasksConstants.ERROR, error } }
    }
}

function checkForChanges(completed, taskIdsBefore){ 
    const viewState = 'incomplete'
    return dispatch => {

        serviceOpsService.getAdminTasks(completed)
            .then(
                data => {
                    dispatch(success(data, viewState));
                    const taskIdsAfter = data.map(task => task.id)
                    const newIds = taskIdsAfter.filter(id => !taskIdsBefore.includes(id));
                    if(newIds.length > 0){
                        dispatch(updateChangeTrigger(true))
                    } 
                },
                error => {
                    dispatch(failure(error));

                }
            )

        function success(data, viewState) { return { type: serviceOpsTasksConstants.UPDATE_TASKS_TABLE, data, viewState } }
        function failure(error) { return { type: serviceOpsTasksConstants.ERROR, error } }
    }
}

function updateChangeTrigger(changeTrigger) { return { type: serviceOpsTasksConstants.UPDATE_CHANGE_TRIGGER, changeTrigger } }

function setTasksCompleted(ids, tableData) {
    const viewState = 'incomplete'
    return dispatch => {
        serviceOpsService.setTasksCompleted(ids)
            .then(
                data => {
                    dispatch(success(tableData, viewState));

                },
                error => {
                    dispatch(failure(error));
                 
                }
            )

        function success(data, viewState) { return { type: serviceOpsTasksConstants.UPDATE_TASKS_TABLE, data, viewState } }
        function failure(error) { return { type: serviceOpsTasksConstants.ERROR, error } }

    }
}

function setTaskToIncomplete(ids, tableData) {
    return dispatch => {

        serviceOpsService.setTaskToIncomplete(ids)
            .then(
                data => {
                    dispatch(success(tableData));

                },
                error => {
                    dispatch(failure(error));
                 
                }
            )
            
        function success(data) { return { type: serviceOpsTasksConstants.UPDATE_TASKS_TABLE, data } }
        function failure(error) { return { type: serviceOpsTasksConstants.ERROR, error } }

    }
}