import { authHeader } from '../_helpers';
import { handleResponse } from './util'

export const instantPricingService = {
  getPrices,
  getBillingWeight,

};



function getPrices(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(process.env.REACT_APP_API_URL + 'InstantPricing', requestOptions).then(handleResponse)

}


function getBillingWeight(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(process.env.REACT_APP_API_URL + 'InstantPricing/GetBillingWeight', requestOptions).then(handleResponse)

}




