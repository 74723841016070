import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SuiTypography from "_softUI/components/SuiTypography";
import SuiBox from "_softUI/components/SuiBox";
import Card from "@mui/material/Card";
import Grid from '@mui/material/Grid';
import { adminActions } from '_actions/admin.actions';
import { CarrierInput } from '_components/Admin/CarrierInput';
import { shipmentActions } from '../../_actions';
import DataTable from '_components/Tables/DataTable';
import SuiButton from '_softUI/components/SuiButton';
import { useLocation } from "react-router-dom";

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  
  function removeUrlParameter(paramKey) {
    const url = window.location.href
    console.log("url", url)
    var r = new URL(url)
    r.searchParams.delete(paramKey)
    const newUrl = r.href
    console.log("r.href", newUrl)
    window.history.pushState({ path: newUrl }, '', newUrl)
  }

export default function ManageCarriers() {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const state = useSelector(state => state.shipment)
    let query = useQuery();


    useEffect(() => {
        const loadtype = state.data.rows.length === 0 ? 'initial': 'update'
        dispatch(shipmentActions.initate(1, loadtype)); // ShipmentTypeEnum.InstantBooking
    
        var selectedId = query.get('id')
    
        if(selectedId){
          dispatch(shipmentActions.getShipmentById(selectedId));
          removeUrlParameter("id")
        }
      }, [])

     const data = {
        columns: [
            { Header: "ID", accessor: "id" },
            { Header: "Customer", accessor: "customer" },
            { Header: "Booking date", accessor: "bookedDate" },
            { Header: "Pickup Country", accessor: "pickupCountry" },
            { Header: "Pickup City", accessor: "pickupCity" },
            { Header: "Delivery Country", accessor: "deliveryCountry" },
            { Header: "Delivery City", accessor: "deliveryCity" },
            { Header: "Carrier", accessor: "carrier" },
            { Header: "Status", accessor: "status" },
            
        ],
        rows: state.data.rows.map(x=> (
            {
              ...x,
              idHidden: x.id,
              bookingDate: x.bookedDate,
              id: x.id,
              idSearch: x.id + ", " + x.bookingDate,
              customer: x.customer,
              user: x.user,
              firstName: x.firstName ?? " ",
              lastName: x.lastName ? x.lastName.charAt(0) + "." : " " ,
              customerSearch: x.customer + " " + x.user,
              pickupCity: x.pickupCity,
              pickupCountry: x.pickupCountry,
              pickupCompany: x.pickupCompany,
              pickupSearch: x.pickupCity + " " + x.pickupCountry + " " + x.pickupCompany,
              deliveryCity:x.deliveryCity,
              deliveryCountry:x.deliveryCountry,
              deliveryCompany:x.deliveryCompany,
              deliverySearch: x.deliveryCity + " " + x.deliveryCountry + " " + x.deliveryCompany,
              carrierLogo: x.carrierLogo,
              carrier: x.carrier,
              carrierSearch: x.carrier,
              pickupReference:x.pickupReference,
              deliveryReference: x.deliveryReference,
              referenceSearch: x.pickupReference + " " + x.deliveryReference,
              bookedPrice: x.bookedPrice,
              bookedPriceSearch: x.bookedPrice,
              status: x.status,
              state: x.state, 
              statusSearch: x.status
          
            }))
    }


     const save = () => {
        dispatch(adminActions.createOrUpdateCarrier(state.carrierInput, handleModalClose));
    } 

    const handleNewModalOpen = () => {

            setModalTitle('Create a carrier')
            dispatch(adminActions.clearSelectedCarrier(), adminActions.initateRoles());
            setModalOpen(true);
    }

    const handleRowClick = (id) => {
        setModalTitle('Carrier')
        dispatch(adminActions.initateSelectedCarrier(id));
        setModalOpen(true);
    }

    const handleModalClose = () => {
        dispatch(adminActions.clearSelectedCarrier());
        setModalOpen(false);
    }

    const handleDelete = () => {
        dispatch(adminActions.deleteCarrier(state.selectedCarrier));
    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start" sx={{ height: '100%' }}>

            <Grid container item xs={12} alignItems="stretch" justifyContent="center">
                <Grid item xs={12} md={12}>
                    <Card sx={{ overflow: "visible", pl: 3, pr: 3 }}>
                            <SuiBox>
                                <Grid container alignItems="strecth" justifyContent="center">
                                </Grid>


                                <SuiBox >
                                    <Grid container spacing={0}>
                                        <Grid item xs={12} sm={12}>

                                        {useMemo(()=>       <DataTable handleRowClick={handleRowClick} canSearch table={data} title={"Bookings"} />
                                            ,[state.data,state.loading,state.tableUpdateLoading])}
                                        </Grid>
                                    </Grid>

                                </SuiBox>
                            </SuiBox>
                            <CarrierInput open={modalOpen} title={modalTitle} handleClose={handleModalClose} save={save}></CarrierInput>
                    </Card>
                </Grid>
            </Grid>

        </Grid>
    )
}
