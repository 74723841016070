import React, { useEffect } from 'react';

import { SearchContainer, ResultsContainer } from '../_components'
import { instantPricingActions } from '../_actions';
import { useSelector, useDispatch } from 'react-redux';

import { InstantPricingContainer } from "_components"
import Grid from '@mui/material/Grid';

import SuiTypography from '_softUI/components/SuiTypography';
import { AdditionalServiceContainer } from '_components/InstantPricing/AdditionalServicesContainer';

export function InstantPricing() {

  const state = useSelector(state => state.instantPricing)
  const history = useSelector(state => state.dashboard)
  const dispatch = useDispatch()



  
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const historyData = history.searchHistorySelected ? history.selectedSearch : ''
    const pickupCountry = history.searchHistorySelected ? history.selectedSearch.originCountry.value : user.country
    const deliveryCountry = history.searchHistorySelected ? history.selectedSearch.deliveryCountry.value : 'AT'
    //dispatch(instantPricingActions.initate(historyData, pickupCountry, deliveryCountry, state.selections))

    // console.log(user.defaultDirection, 'direction')
    switch(user.defaultDirection){
      case "Export": //export
        dispatch(instantPricingActions.initate(historyData, pickupCountry, deliveryCountry, state.selections))

        break;
      case "Import": //import
        dispatch(instantPricingActions.initate(historyData, deliveryCountry, pickupCountry, state.selections))

        break;
      case "Domestic": //domestic
        dispatch(instantPricingActions.initate(historyData, user.country ?? 'FI', user.country ?? 'FI', state.selections))

        break;
      default:
        dispatch(instantPricingActions.initate(historyData, pickupCountry, deliveryCountry, state.selections))
        break;   
    }



  }, [])



  return (
    <>


      <InstantPricingContainer >
        <SearchContainer />
      </InstantPricingContainer>

      <AdditionalServiceContainer /> 
      <ResultsContainer></ResultsContainer>



    </>
  )

}

