export function convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;   
}

export function dateToLocalISO(date) {
    console.log(date)
    if(!date)
    return undefined

    const off    = date.getTimezoneOffset()
    const absoff = Math.abs(off)
    return (new Date(date.getTime() - off*60*1000).toISOString().substr(0,23) +
            (off > 0 ? '-' : '+') + 
            Math.floor(absoff / 60).toFixed(0).padStart(2,'0') + ':' + 
            (absoff % 60).toString().padStart(2,'0'))
}

export const setUserLocalStorage = (originCountry,destinationCountry) => {
    console.log(destinationCountry,originCountry)
    const user = JSON.parse(localStorage.getItem('user'));
    if(originCountry === user.country && destinationCountry !== user.country){
      localStorage.setItem('user',JSON.stringify({...user,'defaultDirection':'Export'}) )
    }
    else if(originCountry !== user.country && destinationCountry === user.country){
      localStorage.setItem('user',JSON.stringify({...user,'defaultDirection':'Import'}) )
    }
    else if(originCountry === user.country && destinationCountry === user.country){
      localStorage.setItem('user',JSON.stringify({...user,'defaultDirection':'Domestic'}) )
    }
}

