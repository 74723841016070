import React from 'react';
import Grid from '@mui/material/Grid';

import { Success } from '../Shared'
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { useSelector } from 'react-redux';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { ShipmentInformationFormActions, instantPricingActions } from '../../_actions';
import { useHistory } from 'react-router-dom';
import SuiButton from "_softUI/components/SuiButton";
import Link from '@mui/material/Link';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';


export default function Ready({ }) {
  const history = useHistory();
  const dispatch = useDispatch()
  const state = useSelector(state => state.ShipmentInformationForm);
  console.log('readystate', state)
  let user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    if (!state.success) {
      history.push('/InstantPricing')
    }
    const user = JSON.parse(localStorage.getItem('user'));
    dispatch(ShipmentInformationFormActions.setReady())

    switch(user.defaultDirection){
      case "Export": //export
        dispatch(instantPricingActions.initate('', user.country, 'AT', false))
     
        break;
      case "Import": //import
        dispatch(instantPricingActions.initate('', 'AT', user.country, false))

        break;
      case "Domestic": //domestic
          dispatch(instantPricingActions.initate('', user.country ?? 'FI', user.country ?? 'FI', false))

          break;
    }
    dispatch(instantPricingActions.initateGoodsRows())
    dispatch(instantPricingActions.setInitialState())
  }, [])

  function backToInstaClick() {
    if (state.data.deadlineForQuotes) {
      history.push('/SpotQuoteProcess')
    } else {
      history.push('/InstantPricing')
    }
  }

  function viewBookingsClick() {
    if (state.data.deadlineForQuotes) {
      history.push('/SpotRequests')
    } else {
      history.push('/Bookings')
    }
  }


  return (
    <>
      <Grid
        display="flex"
        alignItems="center"
        position="relative"
        xs={12}
        container
        item
        p={2}
        mb={1}
        sx={{
          background: '#fff',
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
          width: '100%',
          borderRadius: '16px'
        }}>




        {state.data.file ? (
          <>



            {state.data.file.length > 0 ? (
              <>
                <Success />

                <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ height: '200px' }}>
                  <Grid container spacing={2} justifyContent="center">
                    {state.data.file
                    .filter(x => x.name.includes("Label"))
                    .map((x, i) => 
                      <Grid item key={i}>
                        <Link target="_blank" href={x.path}>
                          <SuiButton variant="outlined" color="secondary" sx={{ width: 'fit-content', height: '70px' }}>
                            <FileDownloadIcon variant="contained" color="secondary" sx={{ marginRight: '5px' }} />
                            Download labels ({x.type})
                          </SuiButton>
                        </Link>
                      </Grid>
                    )}

                  </Grid>
                  <Grid container spacing={2} justifyContent="center">
                    {state.data.file
                    .filter(x => x.name.includes("Doc"))
                    .map((x, i) => 
                      <Grid item key={i}>
                        <Link target="_blank" href={x.path}>
                          <SuiButton variant="outlined" color="secondary" sx={{ width: 'fit-content', height: '70px' }}>
                            <FileDownloadIcon variant="contained" color="secondary" sx={{ marginRight: '5px' }} />
                            Download waybill ({x.type})
                          </SuiButton>
                        </Link>
                      </Grid>
                    )}

                  </Grid>
                </Grid>
                <List sx={{ paddingLeft: "60px", paddingRight: "60px", paddingBottom: "60px" }}>
                  <p style={{ fontWeight: "bold" }}>What happens next?</p>
                  <ListItem style={{ marginTop: "30px" }}>
                    <ListItemIcon>
                      <LocalShippingOutlinedIcon fontSize="large" color="secondary" sx={{ marginRight: "30px" }} />
                    </ListItemIcon>
                    <span style={{ marginLeft: "30px" }}>The carrier will pick up and deliver your shipment.</span>
                  </ListItem>
                  <ListItem style={{ marginTop: "30px" }}>
                    <ListItemIcon>
                      <FilePresentIcon fontSize="large" color="secondary" sx={{ marginRight: "30px" }} />
                    </ListItemIcon>
                    <span style={{ marginLeft: "30px" }}>The labels can also be found attached to the booking on the "Bookings" -tab under "Documents and labels".</span>
                  </ListItem>
                  <ListItem style={{ marginTop: "30px" }}>
                    <ListItemIcon>
                      <ContactSupportOutlinedIcon fontSize="large" color="secondary" sx={{ marginRight: "30px" }} />
                    </ListItemIcon>
                    <span style={{ marginLeft: "30px" }}>If you have any questions, please do not hesitate to contact us at: <br></br> bookings@freightopt.com or +358 40 196 1317. </span>
                  </ListItem>
                </List>
              </>
            ) : (
              <>
                <Grid container justify="center" direction="column" alignItems="center" mb={7} textAlign="center">
                  <Grid item >
                    <ErrorOutlineIcon fontSize="large" color="error" />
                  </Grid>
                  <Grid item >
                    <Typography variant="h5" mt={4} pl={3} pr={3}>
                      <strong>Something went wrong.</strong> <br></br><br></br>Our dedicated customer service team is actively working to resolve the issue and will be in touch with you shortly. <br></br><br></br> If you prefer immediate assistance, feel free to reach out to us directly at: <br></br> bookings@freightopt.com or by phone at +358 40 196 1317. <br></br><br></br>Thank you for your patience.
                    </Typography>
                  </Grid>
                </Grid>


              </>
            )
            }
          </>
        ) : (
          <>
            <Success />
            {state.data.shipmentType == 1 || state.data.shipmentType == 3 && (

              <List sx={{ paddingLeft: "60px", paddingRight: "60px", paddingBottom: "60px" }}>
                <p style={{ marginTop: "30px", fontWeight: "bold" }}>What happens next?</p>
                <ListItem style={{ marginTop: "30px" }}>
                  <ListItemIcon>
                    <PendingActionsOutlinedIcon fontSize="large" color="secondary" sx={{ marginRight: "30px" }} />
                  </ListItemIcon>
                  <span style={{ marginLeft: "30px" }}>Your quote request has been received by our partners and they will work to find the best solution for your requirements.</span>
                </ListItem>
                <ListItem style={{ marginTop: "30px" }}>
                  <ListItemIcon>
                    <AddTaskOutlinedIcon fontSize="large" color="secondary" sx={{ marginRight: "30px" }} />
                  </ListItemIcon>
                  <span style={{ marginLeft: "30px" }}>Once your request has been processed by our partners, their offers will appear in FreightOpt under the "spot requests" -tab.</span>
                </ListItem>
                <ListItem style={{ marginTop: "30px" }}>
                  <ListItemIcon>
                    <HourglassBottomOutlinedIcon fontSize="large" color="secondary" sx={{ marginRight: "30px" }} />
                  </ListItemIcon>
                  <span style={{ marginLeft: "30px" }}>When the deadline for offers close, or if you find an offer suitable earlier, you can proceed to book the shipment on the "spot requests" -tab.</span>
                </ListItem>
                <ListItem style={{ marginTop: "30px" }}>
                  <ListItemIcon>
                    <ContactSupportOutlinedIcon fontSize="large" color="secondary" sx={{ marginRight: "30px" }} />
                  </ListItemIcon>
                  <span style={{ marginLeft: "30px" }}>If you have any questions, please do not hesitate to contact us at: <br></br> bookings@freightopt.com or +358 40 196 1317. </span>
                </ListItem>
              </List>

            )}

            {state.data.shipmentType == 2 && (

              <List sx={{ paddingLeft: "60px", paddingRight: "60px", paddingBottom: "60px" }}>
                <p style={{ marginTop: "30px", fontWeight: "bold" }}>What happens next?</p>


                {/* freightopt sends labels OR it's export and withdriver. email paragraph*/}
                {((state.carrierLabelDeliveryType === 0) || (state.carrierLabelDeliveryType === 1 && state.direction === 2)) && (
                  <>
                    <ListItem style={{ marginTop: "30px" }}>
                      <ListItemIcon>
                        <AttachEmailOutlinedIcon fontSize="large" color="secondary" sx={{ marginRight: "30px" }} />
                      </ListItemIcon>
                      {/* export or domestic */}
                      {(state.direction === 2 || state.direction === 1) &&
                        <span style={{ marginLeft: "30px" }}>In a few moments, label(s) will be sent to you by email. The label(s) will also be attached to the booking on the "Bookings" -tab. Please print out and attach the label(s) to your shipment.</span>
                      }
                      {/* import or crossland */}
                      {(state.direction === 3 || state.direction === 4) &&
                        <span style={{ marginLeft: "30px" }}>In a few moments, label(s) will be sent to you by email. The documents have also been forwarded to the loading place for printing. The label(s) will also be attached to the booking on the "Bookings" -tab. </span>
                      }
                    </ListItem>
                  </>
                )}

                {/* driver brings labels AND it's import. OR no labels at all. email paragraph */}
                {((state.carrierLabelDeliveryType === 1 && (state.direction === 3 || state.direction === 4)) || state.carrierLabelDeliveryType === 2) && (
                  <>
                    <ListItem style={{ marginTop: "30px" }}>
                      <ListItemIcon>
                        <EmailOutlinedIcon fontSize="large" color="secondary" sx={{ marginRight: "30px" }} />
                      </ListItemIcon>
                      {/* export or domestic */}
                      {(state.direction === 2 || state.direction === 1) &&
                        <span style={{ marginLeft: "30px" }}>In a few moments, you will receive a confirmation email for your booking. </span>
                      }
                      {/* import or crossland */}
                      {(state.direction === 3 || state.direction === 4) &&
                        <span style={{ marginLeft: "30px" }}>In a few moments, you will receive a confirmation email for your booking. The e-mail has also been sent to the loading place for their information.</span>
                      }
                    </ListItem>
                  </>
                )}


                <ListItem style={{ marginTop: "30px" }}>
                  <ListItemIcon>
                    <LocalShippingOutlinedIcon fontSize="large" color="secondary" sx={{ marginRight: "30px" }} />
                  </ListItemIcon>

                  {/* if it's import or crossland it will show that driver brings labels if that's the carrierLabelDeliveryType */}
                  {(state.direction === 3 || state.direction === 4) && state.carrierLabelDeliveryType === 1 ? (
                    <span style={{ marginLeft: "30px" }}> The driver will bring the freight documents to the loading place. </span>
                  ) : (
                    <span style={{ marginLeft: "30px" }}>The carrier will pick up your shipment.</span>
                  )}
                </ListItem>

                {state.carrierLabelDeliveryType !== 2 && (
                  <ListItem style={{ marginTop: "30px" }}>
                    <ListItemIcon>
                      <PlaceOutlinedIcon fontSize="large" color="secondary" sx={{ marginRight: "30px" }} />
                    </ListItemIcon>
                    <span style={{ marginLeft: "30px" }}>The shipment can be traced on the "Bookings" -tab or on the carrier's webpage with the tracking ID. The tracking ID can be found on the booking and in the booking-email.</span>
                  </ListItem>
                )}


                <ListItem style={{ marginTop: "30px" }}>
                  <ListItemIcon>
                    <ContactSupportOutlinedIcon fontSize="large" color="secondary" sx={{ marginRight: "30px" }} />
                  </ListItemIcon>
                  <span style={{ marginLeft: "30px" }}>If you have any questions, please do not hesitate to contact us at: <br></br> bookings@freightopt.com or +358 40 196 1317. </span>
                </ListItem>

              </List>
            )}
          </>
        )}
        <Grid sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <SuiButton onClick={backToInstaClick} type="submit" variant="contained" color="primary" sx={{ marginRight: '10px' }}>
            {state.data.deadlineForQuotes ? "Create another" : "Back to pricing"}
          </SuiButton>

          <SuiButton onClick={viewBookingsClick} type="submit" variant="contained" color="secondary" sx={{ marginLeft: '10px' }}>
            {state.data.deadlineForQuotes ? "View requests" : "View bookings"}
          </SuiButton>
        </Grid>


      </Grid >
    </>


  )
}