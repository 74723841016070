import React, {  useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { ShipmentInformationFormActions, locationActions } from '../../_actions';
import ReactCountryFlag from "react-country-flag"
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import SuiButton from '_softUI/components/SuiButton';
import Collapse from '@mui/material/Collapse';
import Label from '_components/FormFields/Label'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import SuiBadge from "_softUI/components/SuiBadge";
import LocationInputForm from '../Common/LocationInputForm'


export function Location({ title, index, instant, next, visible, validateLength }) {

  const dispatch = useDispatch()
  const location = useSelector(state => state.ShipmentInformationForm.data.locations[index])

  const savedLocation = useSelector(state => state.location)

  const savedLocationList = savedLocation.locations.filter(function (val) {
    return (instant && val.zip === location.zip && val.countryAlpha2 == location.countryAlpha2) || !instant
  })
  const countries = useSelector(state => state.instantPricing).countries;

  function saveLocation() {
    console.log('save', location)
    dispatch(locationActions.create(location));
  }

  function loadSavedLocation(e, index) {
    console.log(e)
    if (e !== null) {
      const loc = savedLocation.locations.find(loc => {
        return loc.id === e.value
      })

      dispatch(ShipmentInformationFormActions.setSavedLocation(loc, index))
    }

  }

  function presetSavedLocation(index) {
      console.log('index', index)
      if (savedLocationList.length === 1) {
        const loc = savedLocationList[0]
        console.log('loc', loc)
      dispatch(ShipmentInformationFormActions.setSavedLocation(loc, index))
    }
  }

  useEffect (() => {
    if(instant){
      presetSavedLocation (index)
    }

  }, []   
    )

  function clearLocationInputs(event, index) {
    dispatch(ShipmentInformationFormActions.clearLocationInputs(index, instant))
  }

    function handleLocationChange(event, index) {

    dispatch(ShipmentInformationFormActions.updateLocationValue(event.target.name, event.target.value, index));
  }

  function handleLocationChangeWithMaxLenght(event, index, label, maxLenght) {
    const newValue = validateLength(label, event.target.value, maxLenght)
    dispatch(ShipmentInformationFormActions.updateLocationValue(event.target.name, newValue, index));
  }

  function handleCountryChange(name, value, index) {

    dispatch(ShipmentInformationFormActions.updateLocationValue(name, value, index));
  }

  function setCollapseUnvisible() {

    next('locations');

  }

  


  const countryOptions = countries.map((x, index) =>
    ({ value: x.alpha2, 
      label: <React.Fragment key={x.name} ><span><ReactCountryFlag svg   countryCode={x.alpha2} width="20" /></span><span style={{ marginLeft: '10px' }}>{x.name}</span></React.Fragment> 
    }))


  return (
    // <ContainerBox sx={getStyles()} onClick={() => {!show && !collapseOpen && setCollapseVisible()}}>
    <Grid container item xs={!visible ? 6 : 12} spacing={0} justifyContent="space-evenly" alignItems="stretch">
      <Grid item xs={12}>

        <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color={!visible ? 'mainBlue' : 'secondary'} badgeContent={title} />
      </Grid>
      <Grid item container xs={12} sx={{ paddingTop: '0 !important', marginBottom: '1rem' }}  >

        {!visible &&
          <>
            <Grid item container xs={12} spacing={2}>

              <Grid item xs={6} >
                <Label icon={<BusinessOutlinedIcon color="secondary" />} value={location.name} />
                <Label icon={<LocationOnOutlinedIcon color="secondary" />} value={location.streetAddress + ', ' + location.zip + ' ' + location.city} />
                <Label icon={<PublicRoundedIcon color="secondary" />} value={location.countryAlpha2} ><ReactCountryFlag svg   countryCode={location.countryAlpha2} width="20" style={{ marginLeft: '0.5rem' }} /></Label>
              </Grid>
              <Grid item xs={6} >
                <Label icon={<PersonOutlineOutlinedIcon color="secondary" />} value={location.contactName} />
                <Label icon={<EmailOutlinedIcon color="secondary" />} value={location.contactEmail} />
                <Label icon={<AccessTimeRoundedIcon color="secondary" />} value={location.openingHours} />
              </Grid>

            </Grid>

          </>
        }


        <Grid item container xs={12} >
          <Collapse in={visible}>


          <LocationInputForm 
          
          title={title} 
          index={index} 
          handleInputChange={e =>  handleLocationChange (e, index)} 
          handleCountryChange={e => handleCountryChange('countryAlpha2', e.value, index)}
          location={location} 
          countryOptions={countryOptions}  
          locationOptions={savedLocationList}        
          instant={instant}
          loadSavedLocation={loadSavedLocation}
          handleLocationChangeWithMaxLenght={handleLocationChangeWithMaxLenght}
          />
{                <Grid item xs={12} sx={{ marginTop: '1rem' }} container justifyContent="left" spacing={1}>
                  <Grid item container xs={9} spacing={2}>



                    <Grid item xs={3} sx={{ paddingTop: '0rem !important' }}>
                      <SuiButton variant="text" startIcon={<SaveIcon />} size="small"  sx={{ fontSize: '0.7rem', pl: 1, pr: 1, boxShadow: 'none !important' }} color="dark" onClick={e => saveLocation()} disabled={savedLocation.loading} >
                        Save location
                      </SuiButton>
                    </Grid>
                    <Grid item xs={3} sx={{ paddingTop: '0rem !important' }}>
                      <SuiButton variant="text" startIcon={<DeleteIcon />} size="small"  sx={{ fontSize: '0.7rem', pl: 1, pr: 1, boxShadow: 'none !important' }} color="error" onClick={e => clearLocationInputs(e, index)} disabled={savedLocation.loading} >
                        Clear location
                      </SuiButton>
                    </Grid>
                  </Grid>
                  {index > 0 && next &&
                    <Grid item xs={3} sx={{ textAlign: 'right', paddingTop: '0rem !important' }}>
                      <SuiButton color="secondary" variant="contained" onClick={setCollapseUnvisible}>
                        Apply
                      </SuiButton>
                    </Grid>}
                </Grid>}
          </Collapse>
        </Grid>
      </Grid>

    </Grid>



  )
}