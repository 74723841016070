/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React base styles
import colors from "_softUI/assets/theme/base/colors";
import typography from "_softUI/assets/theme/base/typography";

// Soft UI Dashboard PRO React helper functions
import pxToRem from "_softUI/assets/theme/functions/pxToRem";

const { dark } = colors;
const { size, fontWeightLight } = typography;

export default {
  styleOverrides: {
    root: {
      display: "block",
      minHeight: pxToRem(24),
      marginBottom: pxToRem(2),
    },

    label: {
      display: "inline-block",
      fontSize: size.sm,
      fontWeight: fontWeightLight,
      // color: dark.main,
      lineHeight: 1,
      transform: `translateY(${pxToRem(1)})`,
      marginLeft: pxToRem(0),

      "&.Mui-disabled": {
        color: dark.main,
      },
    },
  },
};
