import React from 'react';
import { incoterms, transportationTypes } from '_helpers'
import Grid from '@mui/material/Grid';
import DisplayWithLabel from '_components/FormFields/DisplayWithLabel'
import { useDispatch, useSelector } from 'react-redux';
import SuiBox from "_softUI/components/SuiBox";
import CardMedia from "@mui/material/CardMedia";
import SuiTypography from "_softUI/components/SuiTypography";
import Divider from '@mui/material/Divider';
import Label from '_components/FormFields/Label'
import SuiBadge from "_softUI/components/SuiBadge";
import InputWithLabel from "_components/FormFields/InputWithLabel";
import { customerSpotRequestTableActions } from '_actions';
import LinearProgress from '@mui/material/LinearProgress';
import { Box } from "@mui/material";
import { useConfirm } from 'material-ui-confirm';
import { convertUTCDateToLocalDate, dateToLocalISO } from '_helpers';

export default function BasicInfoView({ state, instant, currency = "€", carrier = false }) {

  let today = new Date()
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const stateParent = useSelector(state => state.ShipmentInformationForm);
  function handleDeadlineChange(event) {


    const model = {
      id:state.id,
      deadline: event.target.value,
      timezone:  Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
    dispatch(customerSpotRequestTableActions.updateDeadline(model))

  }

  console.log(state)
  const deadlineConverted = instant || state.deadlineForQuotes === ""  ? "" :  dateToLocalISO(convertUTCDateToLocalDate(new Date(state.deadlineForQuotes))).slice(0,16)



  return (
    <React.Fragment>
      <Grid container sx={{ mb: '1rem' }}>
        <SuiBox mb={0} mt={2} lineHeight={0} display="inline-block" borderRadius="0.75rem" sx={{ width: '100%', height: "100%" }} >

          <Grid item container xs={12}>
            <Grid item xs={2} mb={2}>
              <SuiTypography variant="h5" fontWeight="bold" >
                General
              </SuiTypography>
            </Grid>
            <Grid item xs={10} >
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Grid container item spacing={1}>
                    {instant &&
                      <Grid container item xs={2} spacing={1} sx={{ textAlign: 'center' }}>
                        <Grid item xs={12}>
                          <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent={"Carrier"} />
                        </Grid>
                        <Grid item xs={12} container justifyContent="center" alignItems="flex-start">

                          <CardMedia
                            src={state.selectedCarrierLogo}
                            component="img"
           
                            title={state.selectedCarrier ?? 'n/a'}
                            alt={state.selectedCarrier ?? 'n/a'}
                            sx={{

                              margin: 0,
                              objectFit: "scale-down",
                              objectPosition: "center",
                              borderRadius: '0.2rem',
                              textAlign: 'center',
                              pl: '0.5rem',
                              pr: '0.5rem'

                            }}
                          />
                             {state.productName && 
                            <Grid item xs={12} sx={{marginTop:'-0.9rem'}}>
                              <SuiTypography variant='subtitle2' sx={{ color: 'text.secondary', textAlign: 'center', fontSize:'0.75rem' }} >

                                {state.productName}


                              </SuiTypography>


                            </Grid>
                          }

                        </Grid>
                      </Grid>}
                    <Grid container item xs={2} spacing={1} sx={{ textAlign: 'center' }}>
                      <Grid item xs={12}>
                        <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent={"Sender reference"} />
                      </Grid>
                      <Grid item xs={12} sx={{ wordBreak: 'break-word'}} ml={1}>
                        <Label value={state.senderReference ?? 'n/a'} />
                      </Grid>
                    </Grid>
                    <Grid container item xs={2} spacing={1} sx={{ textAlign: 'center'}}>
                      <Grid item xs={12}>
                        <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent={"Receiver reference"} />
                      </Grid>
                      <Grid item xs={12} sx={{ wordBreak: 'break-word'}} ml={1}>
                        <Label value={state.receiverReference ?? 'n/a'} />
                      </Grid>
                    </Grid>
                    <Grid container item xs={3} spacing={1} sx={{ textAlign: 'center' }}>
                      <Grid item xs={12}>
                        <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent={"Goods ready for pickup"} />
                      </Grid>
                      <Grid item xs={12} ml={1}>
                        <Label value={state.pickupStart.slice(8, 10) + '.'+state.pickupStart.slice(5, 7)+ '.'+state.pickupStart.slice(0, 4) ?? 'n/a'} />
                      </Grid>
                    </Grid>
                    {state.trackingNumber &&
                      <Grid container item xs={3} spacing={1} sx={{ textAlign: 'center' }}>
                        <Grid item xs={12}>
                          <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent={"Tracking number"} />
                        </Grid>
                        <Grid item xs={12} ml={1}>
                          <Label value={state.trackingNumber} />
                        </Grid>
                      </Grid>
                    }
                    {!instant && !stateParent.preview && !carrier && 
                      <>
                        <Grid container item xs={3} spacing={1} sx={{ textAlign: 'center' }}>
                          <Grid item xs={12}>
                            <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent={"Deadline for quotes"} />
                          </Grid>
                          <Grid item xs={12}>
                            {state.updating &&
                              <Box sx={{ width: '100%', marginBottom: '1px' }}>
                                <LinearProgress />
                              </Box>
                            }
                            {!state.updating &&
                              <InputWithLabel
                                label=""
                                name="deadlineForQuotes"
                                onChange={e => handleDeadlineChange(e)}
                                fullWidth
                                value={deadlineConverted}
                                type="datetime-local"

                                inputProps={{ min: today.toISOString().slice(0, 16) }}

                              />
                            }
                          </Grid>
                        </Grid>



                      </>

                    }

                    {((!instant && stateParent.preview) || carrier) && 
                      <>
                      <Grid container item xs={3} spacing={1} sx={{ textAlign: 'center' }}>
                        <Grid item xs={12}>
                          <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent={"Deadline for quotes"} />
                        </Grid>
                        <Grid item xs={12}>
                          <Label value={state.deadlineForQuotes.slice(8, 10) + '.'+state.deadlineForQuotes.slice(5, 7)+ '.'+state.deadlineForQuotes.slice(0, 4) + " " + state.deadlineForQuotes.substring(11, 16) ?? 'n/a'} />
                        </Grid>
                      </Grid>



                      </>

                    }









                    <Grid item container xs={12} spacing={2} sx={{ marginTop: '1rem' }}>

                      {!instant &&
                        <>
                          <Grid container item xs={2} spacing={1} sx={{ textAlign: 'center' }}>
                            <Grid item xs={12}>
                              <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent={"Transportation type"} />
                            </Grid>
                            <Grid item xs={12}>
                              <Label value={ state.transportationType !== 0 && state.transportationType !== '' ?  transportationTypes.find(x => x.value === state.transportationType).label : ""} />
                            </Grid>
                          </Grid>
                          </>
                      }

                          <Grid container item xs={2} spacing={1} sx={{ textAlign: 'center' }}>
                            <Grid item xs={12}>
                              <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent={"Incoterm"} />
                            </Grid>
                            <Grid item xs={12}>
                              <Label value={state.incoterm !== '' ? incoterms.find(x => x.value === state.incoterm)?.label ?? "n/a" : "n/a"} />
                            </Grid>
                          </Grid>
                          <Grid container item xs={2} spacing={1} sx={{ textAlign: 'center' }}>
                            <Grid item xs={12}>
                              <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent={"Incoterm location"} />
                            </Grid>
                            <Grid item xs={12}>
                              <Label value={state.incotermLocation ?? 'n/a'} />
                            </Grid>
                          </Grid>




                    




                      <Grid item xs={6} >
                        <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent="Additional services" />
                        {state.tailLift && <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="white" badgeContent={state.groupage || state.shipmentDirection === 1 ? "Tail lift loading / dismantling" : state.shipmentDirection === 2 ? "Tail lift loading" : "Tail lift dismantling"} />}
                        {state.insurance && <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="white" badgeContent={"Insured goods value: " + state.goodsValue.toString() + " " + currency} />}
                        {state.adr && <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="white" badgeContent={"ADR Class " + state.goods.flatMap(goodsRow => goodsRow.adrRow.map(x => x.class)).join(', ')} />}
                        {state.importDeclaration && <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="white" badgeContent={"Import declarations"} />}
                        {state.exportDeclaration && <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="white" badgeContent={"Export declarations"} />}
                        {state.callOnPickup && <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="white" badgeContent={"Call before pickup"} />}
                        {state.callOnDelivery && <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="white" badgeContent={"Call before delivery"} />}
                        {state.consumerDelivery && <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="white" badgeContent={"Consumer delivery"} />}
                        {state.temperatureControl && <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="white" badgeContent={"Temperature: " + state.temperature.toString() + "C°"} />}
                        {state.heatedTransport && <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="white" badgeContent={"Heated transport"} />}






                      </Grid>
                    </Grid>



                  </Grid>
                </Grid>
              </Grid>
            </Grid>


          </Grid>


        </SuiBox>

      </Grid>

    </React.Fragment>
  );

}





