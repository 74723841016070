import React, { useState } from 'react';
import { DataGrid  } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import {
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';

import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CustomToolbar from './CustomToolbar';
import { Loading } from '_components/Shared/LoadingSpinner'

const RowDetail = ({ data }) => (
  <div style={{ padding: 16, background: '#f5f5f5' }}>
    <h4>Details for {data.name}</h4>
    <p>{data.description}</p>
  </div>
);


export default function EditableDataTable({ rows, columns, saveRow, updateRow, openModal, columnVisibility, columnVisibilityOptions, columnVisibilityChange, checkedRowActions, moveSelected, loading }) {
  const [rowModesModel, setRowModesModel] = useState({});
  const [selectionModel, setSelectionModel] = useState([]);

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = (newRow) => {
    saveRow(newRow)
    return newRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleRowOpen = (id) => () => {
    openModal(id);
  };



  const actionColumn = {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    width: 100,
    cellClassName: 'actions',
    getActions: ({ id }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            color="primary"
            onClick={handleSaveClick(id)}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color="secondary"
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(id)}
          color="secondary"
        />,
        <GridActionsCellItem
          icon={<OpenInNewIcon />}
          label="Open"
          className="textPrimary"
          onClick={handleRowOpen(id)}
          color="primary"
        />,
      ];
    }
  }
  

  const updatedColumns = [...columns, actionColumn];
  


  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      {/* <FormGroup row>
        {columnVisibilityOptions.map((option) => (
          <FormControlLabel
            key={option.id}
            control={
              <Checkbox
                checked={option.columns.every((column) => columnVisibility[column])}
                onChange={() => columnVisibilityChange(option.columns)}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup> */}
      <DataGrid
        rowHeight={38}
        // rowHeight = {100}
        rows={rows}
        columns={updatedColumns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20]}
        checkboxSelection
        selectionModel={selectionModel}
        onRowSelectionModelChange={(ids) => {
          setSelectionModel(ids);
        }}
        disableRowSelectionOnClick
        sx={{ fontSize: '12px' }}
        columnVisibilityModel={columnVisibility}
        slots={{ toolbar: () => (<CustomToolbar columnVisibility={columnVisibility} columnVisibilityOptions={columnVisibilityOptions} columnVisibilityChange={columnVisibilityChange} checkedRowActions={checkedRowActions} selectionModel={selectionModel} moveSelected={moveSelected}/>)}}
        loading={loading}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
      />
    </Box>
  );
}