import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SuiBox from "_softUI/components/SuiBox";
import Card from "@mui/material/Card";
import Grid from '@mui/material/Grid';
import { serviceOpsFollowupActions } from '../../_actions';
import { useLocation } from "react-router-dom";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import ShipmentCommentsInput from '../../_components/Admin/ServiceOps/ShipmentCommentsInput'
import CustomToolbar from '../../_components/Tables/EditableTable/CustomToolbar';
import SuiButton from '_softUI/components/SuiButton';
import Paper from '@mui/material/Paper';
import Drawer from '@mui/material/Drawer';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';



function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function AdminFollowupTab() {
  const dispatch = useDispatch();
  const shipmentState = useSelector(state => state.serviceOpsShipment)
  const state = useSelector(state => state.serviceOpsFollowup)


  let query = useQuery();
  const [dialogOpen, setDialogOpen] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [drawerView, setDrawerView] = useState('comments')
  const [selectedId, setSelectedId] = useState()
  const [columnVisibility, setColumnVisibility] = useState({
    trackingNumber: false,
    pickupReference: false,
    pickupCountry: false,
    deliveryCountry: false
  })


  useEffect(() => {
    dispatch(serviceOpsFollowupActions.initate());
  }, [])


  const carrierOptions = shipmentState.carrierOptions.map(carrier => carrier.name);


  const data = {
    columns: [
      { headerName: "ID", field: "id", width: 60 },
      { headerName: "Customer", field: "customer", width: 180 },
      { headerName: "Booking date", field: "bookedDate" },
      { headerName: "Tracking number", field: "trackingNumber", width: 140 },
      { headerName: "Pickup ref", field: "pickupReference" },
      { headerName: "From", field: "pickupCountry", width: 90 },
      { headerName: "To", field: "deliveryCountry", width: 90 },
      { headerName: "Carrier", field: "carrier", type: 'singleSelect', valueOptions: carrierOptions },
      { headerName: "Status", field: "status", width: 150 },
      { headerName: "", field: "statusTime", width: 100 },
      { headerName: "Comment", field: "comment", width: 130},
      { headerName: "", field: "commentDate", width: 100 },
      {
        headerName: "Add", field: "add", renderCell: (params) => (<GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(params.id)}
          color="secondary"
        />), width: 50
      }
    ],
    rows: state.data.rows.map(x => (
      {
        ...x,
        idHidden: x.id,
        bookingDate: x.bookedDate,
        id: x.id,
        idSearch: x.id + ", " + x.bookingDate,
        trackingNumber: x.trackingNumber,
        pickupReference: x.pickupReference,
        customer: x.customer,
        user: x.user,
        pickupCountry: x.pickupCountry + ", " + x.pickupZip,
        deliveryCountry: x.deliveryCountry + ", " + x.deliveryZip,
        carrierLogo: x.carrierLogo,
        carrier: x.carrier,
        pickupReference: x.pickupReference,
        deliveryReference: x.deliveryReference,
        bookedPrice: x.bookedPrice,
        bookedPriceSearch: x.bookedPrice,
        status: x.status,
        statusTime: new Date(x.statusTime).toLocaleDateString('en-GB'),
        state: x.state,
        statusSearch: x.status,
        purchasePrice: x.purchasePrice,
        comment: x.comment,
        commentDate: x.commentDate,
        profit: x.purchasePrice > 0 ? (x.bookedPrice - x.purchasePrice).toFixed(2) + ' €' : 'n/a',
        margin: x.purchasePrice > 0 ? (((x.bookedPrice - x.purchasePrice) / x.bookedPrice) * 100).toFixed(2) + ' %' : 'n/a'
      }))
  }


  const columnVisibilityOptions = ([
    {
      id: 1, label: 'Show carrier IDs', columns: [
        'trackingNumber',
        'pickupReference',
      ]
    },
    { id: 2, label: 'Show locations', columns: ['pickupCountry', 'deliveryCountry'] },
  ])

  const handleColumnVisibilityChange = (columns) => {
    setColumnVisibility((prevVisibility) => {
      const updatedVisibility = { ...prevVisibility };

      columns.forEach((column) => {
        updatedVisibility[column] = !prevVisibility[column];
      });

      return updatedVisibility;
    });
  };

  const handleEditClick = (id) => () => {
    const event = {
      target: {
        name: 'shipmentId',
        value: id
      }
    }
    handleInputChange(event)
    setDialogOpen(true)
  };

  const handleInputChange = (event) => {
    dispatch(serviceOpsFollowupActions.updateCommentValue(event.target.name, event.target.value));
  }


  const handleModalClose = () => {
    setDialogOpen(false)
  }

  const handleSubmitComment = () => {
    dispatch(serviceOpsFollowupActions.addComment(state.shipmentComment))
    handleModalClose()
  }

  const handleRowClick = (params) => {
    if(params.field !== "add")
    {
      setDrawerOpen(true)
      dispatch(serviceOpsFollowupActions.getCommentsAndStatusesById(params.id))
    }
  }


  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      sx={{ height: '100%', width: '100%' }}>

      <Grid container item xs={12} alignItems="stretch" justifyContent="center">
        <Grid item xs={12} md={12}>
          <Card sx={{ overflow: "auto" }}>
            <SuiBox>
              <Grid container alignItems="strecth" justifyContent="center">
              </Grid>


              <SuiBox >
                <Grid container spacing={0} sx={{ height: '100%' }}>
                  <Grid item xs={12} sm={12} sx={{ height: '100%' }}>
                    <Box sx={{ width: '100%', height: '100%' }}>
                      {useMemo(() => (
                        <DataGrid
                          onCellClick={handleRowClick}
                          rowHeight={38}
                          rows={data.rows}
                          columns={data.columns}
                          getRowClassName={(params) =>
                            params.row.comment === 'Moved to follow up' || params.row.comment === null ? 'highlight-row' : ''
                          }
                          sx={{
                            fontSize: '12px',
                            '& .MuiDataGrid-row': {
                              '&.highlight-row': {
                                backgroundColor: '#FFF9E2',
                                '&:hover': {
                                  backgroundColor: '#FFF9E2',
                                }
                              },
                            },
                          }}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 100,
                              },
                            },
                          }}
                          pageSizeOptions={[100]}
                          columnVisibilityModel={columnVisibility}
                          slots={{ toolbar: () => (<CustomToolbar columnVisibility={columnVisibility} columnVisibilityOptions={columnVisibilityOptions} columnVisibilityChange={handleColumnVisibilityChange} selectionModel={[]} />) }}
                          loading={state.loading}
                          slotProps={{
                            loadingOverlay: {
                              variant: 'skeleton',
                              noRowsVariant: 'skeleton',
                            },
                          }}
                        />
                      ), [data.rows, data.columns, state.loading, columnVisibility])}
                    </Box>
                  </Grid>
                </Grid>

              </SuiBox>
            </SuiBox>
          </Card>
        </Grid>
      </Grid>
      <ShipmentCommentsInput open={dialogOpen} selectedId={selectedId} handleClose={handleModalClose} handleInputChange={handleInputChange} handleSubmit={handleSubmitComment} state={state} />
      <Drawer
        anchor="bottom"
        open={drawerOpen}
        disableScrollLock={true}
        disableRestoreFocus={true}
        //variant="persistent"
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            height: '35%',
            width: 'auto',
          },
        }}
        onClose={() => { setDrawerOpen(false) }}
      >
        <Paper sx={{minHeight: '100%'}}> 
          { drawerView === 'comments' && (
          <DataGrid columns={[{ field: 'comment', flex: 3 }, { headerName: (<Grid container xs={12} display="flex" alignItems="center" sx={{ width: '100%'}} ><Grid item xs={6}><span>comment date</span></Grid><Grid item xs={6}><SuiButton variant="outlined" size="small" color="primary" onClick={() => {setDrawerView('statuses')}} > <KeyboardDoubleArrowRightIcon size="large"  /> View statuses</SuiButton></Grid></Grid> ) , field: 'commentDate', flex: 1.5} ]}
       
          rows={state.selectedShipment.shipmentComments.map((x, index) => (
            {
              id: x.id || index,
              comment: x.comment,
              commentDate: x.commentDate,
            }
          ))}
          sx={{'& .MuiDataGrid-columnHeader': { width: '100%'}, '& .MuiDataGrid-columnHeaderTitle': {width: '100%', display: 'flex', justifyContent: 'space-between'},'& .MuiDataGrid-columnHeaderTitleContainerContent': {width: '100%', }}}
          pageSizeOptions={[100]}
          getRowHeight={() => 'auto'}
          loading={state.selectedShipment.loading}
          slotProps={{
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
        />
        )} 
                  { drawerView === 'statuses' && (
          <DataGrid columns={[{ field: 'status', flex: 3 }, { headerName: (<Grid container xs={12} display="flex" alignItems="center" sx={{ width: '100%'}} ><Grid item xs={6}><span>status time</span></Grid><Grid item xs={6}><SuiButton variant="outlined" size="small" color="primary" onClick={() => {setDrawerView('comments')}} > <KeyboardDoubleArrowRightIcon size="large"  /> View comments</SuiButton></Grid></Grid> ) , field: 'time', flex: 1.5} ]}
       
          rows={state.selectedShipment.shipmentStatuses.map((x, index) => (
            {
                          id: x.id || index,
                          status: x.status,
                          time: x.time,
            }
          ))}
          sx={{'& .MuiDataGrid-columnHeader': { width: '100%'}, '& .MuiDataGrid-columnHeaderTitle': {width: '100%', display: 'flex', justifyContent: 'space-between'},'& .MuiDataGrid-columnHeaderTitleContainerContent': {width: '100%', }}}
          pageSizeOptions={[100]}
          getRowHeight={() => 'auto'}
          loading={state.selectedShipment.loading}
          slotProps={{
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
        />
        )} 
        </Paper>
      </Drawer>
    </Grid>
  )
}
