import { authHeader } from '../_helpers';
import { handleResponse } from './util'


export const serviceOpsService = {
    getAdminShipments,
    updateShipmentInformation,
    getCarrierOptions,
    getInvoicingInformation,
    getPricingDetails,
    addPricingDetail,
    getFollowupShipments,
    moveShipmentToFollowup,
    addShipmentComment,
    getAdminTasks,
    setTasksCompleted,
    setTaskToIncomplete,
    updateShipment,
    saveBillingDetails,
    addPricesToShipments,
    getCommentsAndStatusesById
};

function getAdminShipments(type) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
 
    return fetch(process.env.REACT_APP_API_URL + 'ServiceOps/GetAdminShipments?type=' + type, requestOptions).then(handleResponse)

}

function getFollowupShipments(type) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
 
    return fetch(process.env.REACT_APP_API_URL + 'ServiceOps/GetFollowupShipments', requestOptions).then(handleResponse)
}


function getCarrierOptions() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
 
    return fetch(process.env.REACT_APP_API_URL + 'ServiceOps/GetCarrierOptions', requestOptions).then(handleResponse)
}

function getPricingDetails(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
 
    return fetch(process.env.REACT_APP_API_URL + 'ServiceOps/GetPricingDetails?shipmentId=' + id, requestOptions).then(handleResponse)
}

function updateShipmentInformation(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(process.env.REACT_APP_API_URL + 'ServiceOps/UpdateShipmentInformation', requestOptions).then(handleResponse)

}

function getAdminTasks(completed) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(completed)
    };
 
    return fetch(process.env.REACT_APP_API_URL + 'ServiceOps/GetAdminTasks', requestOptions).then(handleResponse)
}

function getInvoicingInformation(ids, carrier) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({SearchIds: ids, carrier: carrier})
    };
 
    return fetch(process.env.REACT_APP_API_URL + 'ServiceOps/GetInvoicingInformation', requestOptions).then(handleResponse)

}

function addPricingDetail (bookingId, data){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    }
    return fetch(process.env.REACT_APP_API_URL + 'ServiceOps/AddPricingDetail/' + bookingId, requestOptions).then(handleResponse)
}

function moveShipmentToFollowup (ids){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(ids)
    }
    return fetch(process.env.REACT_APP_API_URL + 'ServiceOps/moveShipmentToFollowup', requestOptions).then(handleResponse)
}

function addShipmentComment (data){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    }
    return fetch(process.env.REACT_APP_API_URL + 'ServiceOps/addShipmentComment', requestOptions).then(handleResponse)
}

function getCommentsAndStatusesById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
 
    return fetch(process.env.REACT_APP_API_URL + 'ServiceOps/GetCommentsAndStatusesById?shipmentId=' + id, requestOptions).then(handleResponse)
}

function setTasksCompleted (ids){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(ids)
    }
    return fetch(process.env.REACT_APP_API_URL + 'ServiceOps/setTasksCompleted', requestOptions).then(handleResponse)
}

function setTaskToIncomplete (id){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(id)
    }
    return fetch(process.env.REACT_APP_API_URL + 'ServiceOps/setTaskToIncomplete', requestOptions).then(handleResponse)
}

function updateShipment(data) {
    const requestOptions = {
      method: 'POST',
      headers:authHeader(),
      body: JSON.stringify(data)
    };
  
    return fetch(process.env.REACT_APP_API_URL + 'ServiceOps/UpdateShipment', requestOptions).then(handleResponse)
  
  }

  function saveBillingDetails(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };


    return fetch(process.env.REACT_APP_API_URL + 'ServiceOps/SaveBillingDetails', requestOptions).then(handleResponse)
    
}

function addPricesToShipments (data){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    }
    return fetch(process.env.REACT_APP_API_URL + 'ServiceOps/AddPricesToShipments/', requestOptions).then(handleResponse)
}