import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import { Paper } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { serviceOpsInvoicingActions } from '../../_actions';
import DataTable from '_components/Tables/DataTable';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box } from '@mui/material';
import SelectWithLabel from '_components/FormFields/SelectWithLabel';
import * as XLSX from 'xlsx';
import { DataGrid } from '@mui/x-data-grid';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import SuiButton from '_softUI/components/SuiButton';
import CustomToolbar from '../../_components/Tables/EditableTable/CustomToolbar';
import {
    GridToolbarQuickFilter,
    GridToolbarDensitySelector,
    GridToolbarExport,
  } from '@mui/x-data-grid';


export default function AdminInvoicingTab() {
    const [arrayData, setArrayData] = useState([]);
    const [textFieldValue, setTextFieldValue] = useState('');
    const [datas, setDatas] = useState([])
    const [selectionModel, setSelectionModel] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState({})

    const dispatch = useDispatch();
    const state = useSelector(state => state.serviceOpsInvoicing.invoicingInformation)
    const rows = useSelector(state => state.serviceOpsInvoicing.data.rows)
    const invoiceRows = useSelector(state => state.serviceOpsInvoicing.invoicingInformation.rows);
    const carrier = useSelector(state => state.serviceOpsInvoicing.invoicingInformation.selectedCarrier);


    const options = [
        { value: 'DHL', label: 'DHL' },
        { value: 'DSV', label: 'DSV' },
        { value: 'POSTI', label: 'POSTI' },
        { value: 'FEDEX', label: 'FEDEX' },
        { value: 'UPS', label: 'UPS' },
        { value: 'POSTNORD', label: 'POSTNORD' },
    ]

    const handleChange = (event) => {
        setTextFieldValue(event.target.value);
    };

    const handleFileUpload = (e) => {
        const reader = new FileReader();
        reader.readAsBinaryString(e.target.files[0]);
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const parsedData = XLSX.utils.sheet_to_json(sheet);
            dispatch(serviceOpsInvoicingActions.setDataFromExcel(parsedData));
        };
    }

    //   useEffect(() => {
    //     const trackingIds = datas.map(item => item.trackingNumber)
    //     dispatch(serviceOpsInvoicingActions .getInvoicingInformation(trackingIds, carrier))
    //   }, [carrier]);

    const handleProcess = () => {
        const trackingIds = state.excelRows.map(item => item.trackingNumber)
        // const trackingIds = state.excelRows.flatMap(row => row.map(item => item.trackingNumber));
        dispatch(serviceOpsInvoicingActions.getInvoicingInformation(trackingIds, carrier))
        // const arrayFromTextFieldValue = textFieldValue.split('\n').map(item => item.trim()).filter(item => item);
        // setArrayData(arrayFromTextFieldValue);
        // dispatch(serviceOpsInvoicingActions .getInvoicingInformation(textFieldValue.split('\n').map(item => item.trim()).filter(item => item), carrier));
    };

    function handleSelectChange(event, name) {
        dispatch(serviceOpsInvoicingActions.updateCarrierForInvoicing(name, event.value));
    }

    const addPricesToShipments = () => {
        const result = selectionModel.map(selectedId => {
            const selectedRow = rows.find(row => row.id === selectedId)
            return {
                shipmentId: selectedId,
                purchasePrice: selectedRow ? selectedRow.excelPurchasePrice : null
            }
        })
        dispatch(serviceOpsInvoicingActions.addPricesToShipments(result))
      }

    const data = {
        columns: [
            { headerName: "Id", field: "id" },
            { headerName: "Tracking number", field: "trackingNumber" },
            { headerName: "billing weight", field: "billingWeight" },
            { headerName: "Price", field: "bookedPrice" },
            // { headerName: "Current Purchase Price", field: 'purchasePrice'},
            { headerName: "Purchase Price", field: "excelPurchasePrice" },
            { headerName: "Profit", field: "profit" },
            { headerName: "Margin", field: "margin" },
            {
                headerName: "", field: "action",
                renderCell: (params) => {
                    let chipColor;
                    let title;
                    if ((params.row.bookedPrice - params.row.excelPurchasePrice) < 0) {
                        chipColor = "error"
                        title = "Profit too low!"
                    } else if (params.row.purchasePrice !== 0 || params.row.purchasePrice !== null) {
                        chipColor = "warning"
                        title = "Already has a purchase price of " + params.row.purchasePrice + " €"
                    }
                    else {
                        chipColor = "success"
                    }
                    return (
                        <Tooltip title={title}>
                            <Chip color={chipColor} size="small" sx={{ width: '50px', height: '10px' }} />
                        </Tooltip>
                    )
                }
            },
            {
                headerName: '',
                align: 'center',
                field: 'select',
                width: 100,
                renderCell: (params) => (
                    <Checkbox
                        checked={selectionModel.includes(params.id)}
                        onChange={(event) => {
                            const newSelection = event.target.checked
                                ? [...selectionModel, params.id]
                                : selectionModel.filter(id => id !== params.id);
                            setSelectionModel(newSelection);
                        }}
                    />

                )
            }
        ],
        rows: rows.map(x => ({
            id: x.id,
            trackingNumber: x.trackingNumber,
            billingWeight: x.billingWeight,
            bookedPrice: x.bookedPrice,
            purchasePrice: x.purchasePrice,
            excelPurchasePrice: x.excelPurchasePrice,
            profit: x.excelPurchasePrice > 0 ? (x.bookedPrice - x.excelPurchasePrice).toFixed(2) + ' €' : 'n/a',
            margin: x.excelPurchasePrice > 0 ? (((x.bookedPrice - x.excelPurchasePrice) / x.bookedPrice) * 100).toFixed(2) + ' %' : 'n/a'
        }))
    }

    const checkedRowActions = ['Add prices to shipments']
    const buttonText = "Add prices to shipments"
    const columnVisibilityOptions = ([])

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"

            sx={{ height: 'calc(100%)' }}>



            <Grid container item xs={12} sx={{ height: '100%' }} >
                <Paper sx={{ width: '100%', height: '100%' }}>
                    <Grid container item xs={12} mb={4}>

                        <Grid item xs={3} mt={4}>
                            {/* <TextField
                                label="Paste your Excel column here"
                                multiline
                                rows={10}
                                variant="outlined"
                                fullWidth
                                value={textFieldValue}
                                onChange={handleChange}
                            /> */}
                            <input
                                type="file"
                                accept=".xlsx, .xls"
                                onChange={handleFileUpload}
                            />
                        </Grid>
                        <Grid item xs={3} >
                            <SelectWithLabel label="Carrier" options={options} onChange={e => handleSelectChange(e, 'countryId')}>
                            </SelectWithLabel>
                        </Grid>
                        <Grid item xs={3} display='flex' alignItems='flex-end' ml={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleProcess}
                                style={{ marginTop: '16px' }}
                            >
                                Process Input
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" sx={{ height: '100%' }}>
                        <Grid container item xs={12} sx={{ height: '100%' }}>
                            <Paper sx={{ width: '100%', height: '100%' }}>
                                <DataGrid
                                    rowHeight={38}
                                    rows={data.rows}
                                    columns={data.columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 100,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[100]}
                                    sx={{ fontSize: '12px' }}
                                    columnVisibilityModel={columnVisibility}
                                    slots={{
                                        toolbar: () => (
                                            <>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '8px' }}>
                                                    <Box display='flex' alignItems='center'>
                                                        <Button onClick={() => addPricesToShipments()}>Hello</Button>
                                                        {/* <FormGroup row >
                                                        </FormGroup> */}
                                                    </Box>
                                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <Box display='flex' alignItems='center'>
                                                            <GridToolbarDensitySelector />
                                                            <GridToolbarExport />
                                                        </Box>
                                                        <Box >
                                                            {/* Quick Filter */}
                                                            <GridToolbarQuickFilter />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </>
                                        )
                                    }}
                                // slots={{ toolbar: () => (<CustomToolbar checkedRowActions={checkedRowActions} columnVisibility={columnVisibility} columnVisibilityOptions={columnVisibilityOptions} selectionModel={selectionModel} moveSelected={addPricesToShipments}/>) }}
                                />
                            </Paper>
                            {/* <Paper>
                                <TableContainer component={Paper} sx={{ maxHeight: '80vh' }}>
                                    <Table size="small">
                                        <TableHead >
                                            <TableRow>
                                                {data.columns.map((column, index) => (
                                                    <TableCell align="left" key={index}>{column.headerName}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell align="left">{row.id}</TableCell>
                                                    <TableCell align="left">{row.trackingNumber}</TableCell>
                                                    <TableCell align="left">{row.purchasePrice}</TableCell>
                                                    <TableCell align="left">{row.billingWeight}</TableCell>
                                                    <TableCell align="left">{row.bookedPrice}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper> */}


                        </Grid>
                    </Grid>

                    {/* {useMemo(()=>   <BookingsTable exportButton={exportButton}  canSearch loading={state.loading} tableUpdateLoading={state.tableUpdateLoading} handleRowClick={handleRowClick} table={tableData} />
        ,[state.data,state.loading,state.tableUpdateLoading])} */}


                </Paper>
            </Grid>
        </Grid>
    )

}