import { FormatColorReset } from '@mui/icons-material';
import { serviceOpsInvoicingConstants } from '../../_constants';
import { goodsRowModel } from '../models';

const initialState = {
    data: {
        rows: []
    },
    invoicingInformation: {
        selectedCarrier: '',
        rows: [],
        excelRows: [],
    },
};

export function serviceOpsInvoicing(state = initialState, action) {
    switch (action.type) {
        case serviceOpsInvoicingConstants.LOADING:
            switch (action.loader) {
                case 'initial':
                    return {
                        ...state,
                        loading: true

                    };
                case 'update':
                    return {
                        ...state,
                        tableUpdateLoading: true
                    };
            }

        case serviceOpsInvoicingConstants.ERROR:
            return {
                ...state,
                loading: false
            };

        case serviceOpsInvoicingConstants.SET_DATA_FROM_EXCEL:
            return {
                ...state,
                loading: false,
                invoicingInformation: {
                    ...state.invoicingInformation,
                    excelRows: action.data
                }
            }

        case serviceOpsInvoicingConstants.UPDATE_INVOICING_TABLE:
            //let newRows = [];
            const testExcelRows = state.invoicingInformation.excelRows
            const testData = action.data
            testExcelRows.forEach(row => console.log('log', typeof row.trackingNumber));
            testData.forEach(row => console.log('log', typeof row.trackingNumber));

            const newRows = testExcelRows.map(excelRow => {
                const backendRow = testData.find(b => String(b.trackingNumber) === String(excelRow.trackingNumber));
                return {
                    id: backendRow ? backendRow.shipmentId : '',
                    trackingNumber: excelRow.trackingNumber,
                    purchasePrice: backendRow ? backendRow.purchasePrice : '',
                    excelPurchasePrice: excelRow.purchasePrice,
                    billingWeight: backendRow ? backendRow.billingWeight : '',
                    bookedPrice: backendRow ? backendRow.bookedPrice : '',
                };
            });

            // if(state.invoicingInformation.excelRows !== '')
            // {
            //     newRows = state.invoicingInformation.excelRows.flatMap(excelRow => {
            //         const backendRow = action.data.find(b => b.trackingNumber === excelRow.trackingNumber)
            //         return{
            //             id: backendRow ? backendRow.shipmentId : '',
            //             trackingNumber: excelRow.trackingNumber,
            //             purchasePrice: excelRow.purchasePrice,
            //             billingWeight: backendRow ? backendRow.billingWeight : '',
            //             bookedPrice: backendRow ? backendRow.bookedPrice : '',
            //         }
            //     })
            //     console.log('newrows', newRows)
            // } else {
            //     newRows = action.data
            // }

            return {
                ...state,
                loading: false,
                tableUpdateLoading: false,
                data: {
                    rows: newRows
                }
            };

        case serviceOpsInvoicingConstants.UPDATE_INVOICING_CARRIER_VALUE:
            return {
                ...state,
                loading: false,
                tableUpdateLoading: false,
                invoicingInformation: {
                    ...state.invoicingInformation,
                    selectedCarrier: action.data.value,
                    rows: state.invoicingInformation.rows
                }
            };

        default:
            return state
    }
}
